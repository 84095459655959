import { useState, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import SearchIcon from "@mui/icons-material/Search";
import sorries from "../data/sorries.json"; // Import the JSON file

interface Result {
  synonyms?: string[];
  partOfSpeech: string;
  definition: string;
  examples?: string[];
}

interface Syllable {
  count: number;
  list: string[];
}

interface Word {
  word: string;
  pronunciation?: { all: string };
  syllables?: Syllable;
  results: Result[];
  frequency?: number;
  pron?: string;
  fpm?: string;
  success?: boolean;
  sorry?: string;
}

const partOfSpeechAbbreviations: { [key: string]: string } = {
  noun: "[n.]",
  verb: "[v.]",
  adjective: "[adj.]",
  adverb: "[adv.]",
  conjunction: "[conj.]",
  preposition: "[prep.]",
  interjection: "[interj.]",
  pronoun: "[pron.]",
  numeral: "[num.]",
  article: "[art.]",
};

interface DictionaryViewProps {
  open: boolean;
  onClose: () => void;
  word: string;
}

export const DictionaryView = ({ open, onClose, word }: DictionaryViewProps) => {
  const [dictionaryInfo, setDictionaryInfo] = useState({ word: "" } as Word);
  const dialogContentRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(word);

  const handleClose = () => {
    setLoading(false);
    setInputValue("");
    setDictionaryInfo({ word: "" } as Word);
    onClose();
  };

  const handleDictionaryLookup = async (word: string) => {
    const url = `https://wordsapiv1.p.rapidapi.com/words/${word}`;
    const options = {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": "dbe586e365mshff82828cc138029p1866b1jsn973678bea385",
        "X-RapidAPI-Host": "wordsapiv1.p.rapidapi.com",
      },
    };

    setLoading(true);

    try {
      const response = await fetch(url, options);
      let result = await response.json();
      if (!Object.hasOwn(result, "success") && response.status === 200) {
        result.success = true;
      }
      if (result.frequency) {
        result.fpm = result.frequency.toFixed(2);
      } else {
        result.fpm = "";
      }
      let pron = "";
      try {
        if (result.pronunciation?.length && result.syllables?.list?.length) {
          pron = `/${result.pronunciation || result.pronunciation.all}/ (${result.syllables.list.join("-")})`;
        } else if (result.pronunciation?.length) {
          pron = `/${result.pronunciation || result.pronunciation.all}/`;
        } else if (result.syllables?.list?.length) {
          pron = `(${result.syllables.list.join("-")})`;
        }
      } catch (error) {
        console.error(error);
      }
      result.pron = pron;
      if (!result.success) {
        result.sorry = sorries[Math.floor(Math.random() * sorries.length)];
      }
      setDictionaryInfo(result);
    } catch (error) {
      console.log(error);
      setDictionaryInfo({} as Word);
    } finally {
      setLoading(false);
    }
  };

  const handleShareToAnki = () => {
    const share = async () => {
      if (dialogContentRef.current) {
        const innerText = dialogContentRef.current.innerText || "";
        let innerHTML = dialogContentRef.current.innerHTML || "";
        if (innerHTML) {
          innerHTML = innerHTML.replace(/ class="(.*?)"/g, "").replace(/ style="(.*?)"/g, "");
        }
        await navigator.share({
          text: innerHTML ? innerHTML : dictionaryInfo.word,
          title: dictionaryInfo.word,
        });
      }
    };
    share();
  };

  useEffect(() => {
    setInputValue(word);
    if (open && word) {
      handleDictionaryLookup(word);
    }
  }, [open, word]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" gap={1}>
          <TextField
            fullWidth
            variant="outlined"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                handleDictionaryLookup(inputValue);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Press Enter to lookup word">
                    <IconButton onClick={() => handleDictionaryLookup(inputValue)} aria-label="lookup">
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {dictionaryInfo.success && (
            <IconButton onClick={handleShareToAnki} aria-label="share" edge="end">
              <ShareIcon />
            </IconButton>
          )}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="caption" marginLeft="1.5em">
          {dictionaryInfo.pron}
          {dictionaryInfo.fpm && (
            <>
              {" ["}
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title="How rare the word is on a scale of 1 to 7. The smaller the number, the rarer the word. (Read up on Zipf's law for more.)"
                placement="top"
                arrow
              >
                <span
                  style={{
                    textDecoration: "underline dotted",
                    cursor: "help",
                  }}
                >
                  {dictionaryInfo.fpm}
                </span>
              </Tooltip>
              {"]"}
            </>
          )}
          {!dictionaryInfo.success && dictionaryInfo.sorry}
        </Typography>
      </DialogTitle>
      <DialogContent ref={dialogContentRef}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <CircularProgress />
          </Stack>
        ) : (
          dictionaryInfo.results &&
          dictionaryInfo.results.length > 0 && (
            <ol style={{ listStyleType: "decimal" }}>
              {dictionaryInfo.results?.map((result, index) => (
                <li key={index}>
                  {index !== 0 && <hr style={{ width: "60%", margin: "1em auto" }} />}
                  {partOfSpeechAbbreviations[result.partOfSpeech] || result.partOfSpeech} {result.definition}
                  {result.synonyms && (
                    <Typography fontSize={"0.7em"} marginLeft={"0.5em"} marginTop={"0.25em"} marginBottom={"0.25em"}>
                      → {result.synonyms.join(", ")}
                    </Typography>
                  )}
                  {result.examples && (
                    <ul
                      style={{
                        fontSize: "0.7em",
                        listStyleType: "disc",
                        fontStyle: "italic",
                        marginLeft: "1em",
                        marginTop: "0.25em",
                        marginBottom: "0.25em",
                      }}
                    >
                      {result.examples.map((example, index) => (
                        <li key={index}>{example}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};
