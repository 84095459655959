import { openDB } from "idb";
import { createThumbnail } from "./books";
import { dbConfig } from "../config";

export async function getDB() {
  return openDB(dbConfig.dbName, dbConfig.dbVersion, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(dbConfig.fileStoreName)) {
        db.createObjectStore(dbConfig.fileStoreName);
      }
      if (!db.objectStoreNames.contains(dbConfig.objectStoreName)) {
        db.createObjectStore(dbConfig.objectStoreName);
      }
      if (db.objectStoreNames.contains(dbConfig.metadataStoreName)) {
        db.deleteObjectStore(dbConfig.metadataStoreName);
      }
    },
  });
}

export async function doesFileExist(id: string): Promise<boolean> {
  const db = await getDB();
  const file = await db.transaction(dbConfig.fileStoreName).objectStore(dbConfig.fileStoreName).get(id);
  return file !== undefined;
}

export async function saveFile(id: string, file: Blob) {
  const db = await getDB();
  const tx = db.transaction(dbConfig.fileStoreName, "readwrite");
  await tx.objectStore(dbConfig.fileStoreName).put(file, id);
  await tx.done;
}

export async function loadFile(id: string): Promise<Blob | File | undefined> {
  const db = await getDB();
  const file = await db.transaction(dbConfig.fileStoreName).objectStore(dbConfig.fileStoreName).get(id);

  if (!file) return undefined;

  if (id.startsWith("cover-")) {
    return new Blob([file], {
      type: file.type || "image/jpeg",
    });
  }

  return file;
}

export async function saveObject(id: string, o: any) {
  const db = await getDB();
  const tx = db.transaction(dbConfig.objectStoreName, "readwrite");
  await tx.objectStore(dbConfig.objectStoreName).put(o, id);
  await tx.done;
  console.log(`Object ${id} saved successfully`);
}

export async function loadObject(id: string): Promise<any | undefined> {
  const db = await getDB();
  return db.transaction(dbConfig.objectStoreName).objectStore(dbConfig.objectStoreName).get(id);
}

export async function getFile(id: string): Promise<Blob | undefined> {
  const db = await getDB();
  return db.transaction(dbConfig.fileStoreName).objectStore(dbConfig.fileStoreName).get(id);
}

export async function deleteFile(id: string, store: string = dbConfig.fileStoreName) {
  console.log("deleting file with id from store", id, store);
  const db = await getDB();
  const tx = db.transaction(store, "readwrite");
  await tx.objectStore(store).delete(id);
  await tx.done;
}

export async function getArrayBuffer(id: string): Promise<ArrayBuffer | undefined> {
  if (id) {
    const file = await getFile(id);
    if (file) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        return arrayBuffer;
      } catch (error) {
        console.error("Error converting Blob to ArrayBuffer:", error);
      }
    }
  }
  return undefined;
}

export async function calculateFileHash(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result;
      if (!arrayBuffer || !(arrayBuffer instanceof ArrayBuffer)) {
        reject("no target");
      } else {
        try {
          const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
          const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
          const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
          resolve(hashHex);
        } catch (error) {
          reject(error);
        }
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

export async function calculateFileHashFromArrayBuffer(arrayBuffer: ArrayBuffer): Promise<string> {
  try {
    const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    return hashHex;
  } catch (error) {
    console.error("calculating hash fail:", error);
    return "";
  }
}

export async function resizeAndReplaceCovers() {
  const db = await getDB();
  const tx = db.transaction(dbConfig.fileStoreName, "readwrite");
  const store = tx.objectStore(dbConfig.fileStoreName);

  const covers = await store.getAllKeys();
  const coverKeys = covers.filter((key) => typeof key === "string" && key.startsWith("cover-"));

  for (const key of coverKeys) {
    if (typeof key === "string") {
      const coverBlob = (await loadFile(key)) || (await loadObject(key));
      if (coverBlob) {
        try {
          const resizedCoverBlob = await createThumbnail(coverBlob);
          await saveFile(key, resizedCoverBlob);
          console.log("saved resized cover for", key);
        } catch (error) {
          console.error(`Failed to resize cover for ${key}:`, error);
        }
      }
    }
  }

  await tx.done;
  console.log("All covers resized and replaced successfully?");
}
