import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import daynight from 'daynight';
import { defaultPrefs } from '../utils/defaults';
import { getPrefs, getTheme, mergePrefs, pushPrefs, updateUser } from '../utils/books';
import type { Prefs, SnackbarMessage } from '../types/book';
import { throttle } from 'lodash';
import { eventsUrl } from '../config';
import { debounce } from 'lodash';
import { version } from '../../package.json';
import { logger } from '../utils/logger';
import { checkVersion } from '../utils/api';
import { useOnlineStore } from '../store/onlineStore';

interface AppContextType {
  prefs: Prefs;
  changePrefs: (p: Partial<Prefs>) => void;
  effectiveTheme: 'light' | 'dark';
  snackbarMessage: SnackbarMessage | null;
  setSnackbarMessage: (m: SnackbarMessage) => void;
  isOnline: boolean | null;
  setOnline: (o: boolean) => void;
  checkAppVersion: () => void;
}

export const AppContext = createContext<AppContextType>({
  prefs: defaultPrefs,
  changePrefs: () => {},
  effectiveTheme: 'light',
  snackbarMessage: null,
  setSnackbarMessage: () => {},
  isOnline: true,
  setOnline: () => {},
  checkAppVersion: () => {},
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [prefs, setPrefs] = useState<Prefs>(getPrefs());
  const [effectiveTheme, setEffectiveTheme] = useState<'light' | 'dark'>(
    prefs.theme === 'light' ? 'light' : prefs.theme === 'dark' ? 'dark' : daynight().light ? 'light' : 'dark'
  );
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>(null);
  const { isOnline, setOnline } = useOnlineStore();

  const updateEffectiveTheme = () => {
    if (prefs.theme === 'auto') {
      setEffectiveTheme(daynight().light ? 'light' : 'dark');
    } else if (prefs.theme === 'light' || prefs.theme === 'dark') {
      setEffectiveTheme(prefs.theme);
    }
  };

  useEffect(() => {
    updateEffectiveTheme();

    const intervalId = setInterval(updateEffectiveTheme, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [updateEffectiveTheme, prefs.theme]);

  const throttledPushPrefs = throttle(pushPrefs, 5000);

  const changePrefs = (p: Partial<Prefs>) => {
    const newPrefs = mergePrefs(prefs, p);
    setPrefs(newPrefs);
    updateEffectiveTheme();
    updateUser({}, newPrefs);
    throttledPushPrefs();
  };

  const connect = useCallback(() => {
    const eventSource = new EventSource(eventsUrl);
    eventSource.onmessage = (event) => {
      console.log('event:', event);
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'broadcast') {
          setSnackbarMessage({ text: data.text, duration: data.duration || 5000 });
        }
        setOnline(true);
      } catch (error) {
        console.error('Error parsing SSE message:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      eventSource.close();
      //   setSnackbarMessage({ text: 'Could not connect to server. It may be down for maintenance.', duration: null });
    };

    return eventSource;
  }, [eventsUrl, isOnline, setOnline]);

  useEffect(() => {
    if (!isOnline) {
      return;
    }

    let eventSource: EventSource | null = null;

    eventSource = connect();

    return () => {
      eventSource?.close();
    };
  }, [isOnline, connect]);

  const checkAppVersion = useCallback(async () => {
    logger.debug('Checking for app version update');
    const currentVersion = localStorage.getItem('currentVersion');
    if (!currentVersion || currentVersion < version) {
      localStorage.setItem('currentVersion', version);
      setSnackbarMessage({
        text: `Upgraded from ${currentVersion || 'something-or-other'} to ${version}.`,
        duration: 6000,
      });
      return;
    }

    const lastCheckKey = 'lastVersionCheck';
    const currentTime = Date.now();
    const lastCheckTime = parseInt(localStorage.getItem(lastCheckKey) || '0', 10);

    if (currentTime - lastCheckTime < 5 * 60 * 1000) {
      return;
    }

    try {
      const updateRequired = await checkVersion();

      if (updateRequired) {
        localStorage.setItem('currentVersion', version);
        setSnackbarMessage({ text: 'New version available. One moment...', duration: null });
        window.location.reload();
      }
    } catch (error) {
      logger.error('Failed to check version:', error);
    }
  }, [isOnline, version, setSnackbarMessage]);

  //   useEffect(() => {
  //     const updateOnlineStatus = () => {
  //       if (navigator.onLine !== isOnline) {
  //         setOnline(navigator.onLine);
  //       }
  //     };

  //     // Set initial online status
  //     updateOnlineStatus();

  //     // Add event listeners for online/offline events
  //     window.addEventListener('online', updateOnlineStatus);
  //     window.addEventListener('offline', updateOnlineStatus);

  //     // Clean up event listeners on component unmount
  //     return () => {
  //       window.removeEventListener('online', updateOnlineStatus);
  //       window.removeEventListener('offline', updateOnlineStatus);
  //     };
  //   }, [isOnline, setOnline]);

  return (
    <AppContext.Provider
      value={{
        prefs,
        changePrefs,
        effectiveTheme,
        snackbarMessage,
        setSnackbarMessage,
        isOnline,
        setOnline,
        checkAppVersion,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
