// Utility functions for managing books in local storage
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { defaultBookMeta, defaultBookSettings, defaultFavorite, defaultPrefs, defaultStatusBarBoxes } from './defaults';

dayjs.extend(utc);

import type {
  BookMeta,
  BookSettings,
  NoteCommentGroup,
  Note,
  NotesGroup,
  Person,
  Prefs,
  StatusBarBoxInfo,
  UpdateRecord,
  UserInfo,
  NoteComment,
  BookCollection,
  NotesStatus,
  NoteStatus,
  SnackbarMessage,
  GeepersQuery,
} from '../types/book';
import {
  saveObject,
  loadObject,
  getArrayBuffer,
  calculateFileHashFromArrayBuffer,
  saveFile,
  deleteFile,
  resizeAndReplaceCovers,
  doesFileExist,
  getDB,
} from './indexedDB';
import Epub, { EpubCFI, type Book } from 'epubjs';
import type Annotations from 'epubjs/types/annotations';
import { aiUser, dbConfig, localStorageBooksName, localStorageNotesStatusName } from '../config';
import { debounce } from './core';
import { isIOS, isMobileOnly, isDesktop } from 'react-device-detect';
import { logger } from './logger';
import daynight from 'daynight';
import { AppContext } from '../context/AppContext';
import { useContext } from 'react';
import { useOnlineStore } from '../store/onlineStore';
import _ from 'lodash';

let cachedBooks: BookCollection | null = null;
let cachedNotesStatus: NotesStatus | null = null;

export const getBooks = (): BookCollection => {
  if (cachedBooks === null) {
    const booksJson = localStorage.getItem(localStorageBooksName);
    cachedBooks = (booksJson ? JSON.parse(booksJson) : {}) as BookCollection;
  }
  return cachedBooks;
};
export const pruneTrashEntries = (): void => {
  const books = getBooks();
  let pruned = false;

  for (const id in books) {
    if (books[id].location === 'trash') {
      delete books[id];
      pruned = true;
    }
  }

  if (pruned) {
    try {
      updateBooksCache(books);
      console.log('Pruned trash entries from books cache');
    } catch (error) {
      console.error('Error pruning trash entries:', error);
    }
  }
};

export const updateBooksCache = (books: BookCollection) => {
  try {
    cachedBooks = books;
    localStorage.setItem(localStorageBooksName, JSON.stringify(books));
  } catch (error) {
    if (error instanceof DOMException && error.name === 'QuotaExceededError') {
      const usedSpace = calculateUsedStorage();
      console.warn(`Storage quota exceeded. Attempting to prune trash entries. Current usage: ${usedSpace}`);

      pruneTrashEntries();
      const updatedUsedSpace = calculateUsedStorage();

      // Try to update the cache again after pruning
      try {
        localStorage.setItem(localStorageBooksName, JSON.stringify(cachedBooks));
        console.log('Successfully updated books cache after pruning trash entries');
        window.dispatchEvent(
          new CustomEvent('snackbarMessage', {
            detail: `Storage quota was exceeded. Pruned trash entries.\n\nStorage was ${usedSpace} but is now ${updatedUsedSpace}.`,
          })
        );
      } catch (retryError) {
        const message = `Storage quota still exceeded after pruning. Current usage: ${updatedUsedSpace}. Please delete some books manually to free up space.`;
        console.error(message);
        window.dispatchEvent(new CustomEvent('snackbarMessage', { detail: message }));
      }
    } else {
      console.error('Error updating books cache:', error);
    }
  }
};

const calculateUsedStorage = (): string => {
  let total = 0;
  for (let key in localStorage) {
    if (localStorage.hasOwnProperty(key)) {
      total += localStorage[key].length * 2; // Multiply by 2 for UTF-16 encoding
    }
  }
  return (total / 1024 / 1024).toFixed(2) + ' MB';
};

export const getNotesStatus = (): NotesStatus => {
  if (cachedNotesStatus === null) {
    const notesStatusJson = localStorage.getItem(localStorageNotesStatusName);
    cachedNotesStatus = (notesStatusJson ? JSON.parse(notesStatusJson) : {}) as NotesStatus;
  }
  return cachedNotesStatus;
};

export const updateNotesStatus = (notesStatus: NotesStatus) => {
  cachedNotesStatus = notesStatus;
  localStorage.setItem(localStorageNotesStatusName, JSON.stringify(notesStatus));
};

export const getNoteStatus = (noteId: string): NoteStatus | undefined => {
  return getNotesStatus()?.[noteId];
};

export const setNoteStatus = (noteId: string, noteStatus: NoteStatus) => {
  const notesStatus = getNotesStatus();
  notesStatus[noteId] = { timestamp: Date.now(), ...noteStatus };
  updateNotesStatus(notesStatus);
};

export const getTheme = (theme?: string): 'light' | 'dark' => {
  const currentTheme = theme || getPrefs().theme;
  return currentTheme === 'auto'
    ? daynight().light
      ? 'light'
      : 'dark'
    : (currentTheme as 'light' | 'dark') ||
        (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
};

export const getCommentList = (commentGroup?: NoteCommentGroup): NoteComment[] => {
  if (!commentGroup) {
    return [];
  }

  const comments = Object.values(commentGroup).filter((comment) => !comment.deleted);
  if (comments.length === 0) return [];

  return comments.sort((a, b) => {
    // If either comment is missing a timestamp, it should come first
    if (!a.timestamp) return -1;
    if (!b.timestamp) return 1;

    // Otherwise, sort by timestamp
    const dateA = dayjs(a.timestamp).valueOf();
    const dateB = dayjs(b.timestamp).valueOf();
    return dateA - dateB; // Earliest to most recent
  });
};

export const fetchGeepersData = async (data: GeepersQuery) => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  if (!getCurrentUser()) {
    return Promise.resolve({});
  }
  try {
    const response = await fetch('/api/geepers', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const result = await response.json();
    return result['response'];
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const deleteCookie = (name = 'session') => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  console.log('deleted cookie: ', name);
};

export const archiveBook = (id: string) => {
  const books = getBooks();
  const book = books[id];

  if (book) {
    books[id].location = book.location === 'active' ? 'archived' : 'active';
    updateBooksCache(books);
    return books[id].location;
  }
};

function getDifferences<T extends object>(newObj: T, oldObj: T): Partial<T> {
  const diff: Partial<T> = {};
  (Object.keys(newObj) as Array<keyof T>).forEach((key) => {
    // Using a safer approach to check for property existence
    if (Object.prototype.hasOwnProperty.call(oldObj, key) && newObj[key] !== oldObj[key]) {
      diff[key] = newObj[key];
    }
  });
  return diff;
}

const polishBook = (book: BookMeta) => {
  if (!book.notes) {
    book.notes = {};
  }
  const email = getCurrentUser()?.email;
  Object.values(book.notes).forEach((note) => {
    if (email && !note.owner) {
      note.owner = email;
    } else if (note.owner === undefined) {
      note.owner = '';
    }
  });
  // Replace short note keys with note.id
  Object.entries(book.notes).forEach(([key, note]) => {
    if (key.length > 0 && key.length < 5 && note.id) {
      delete book.notes[key];
      book.notes[note.id] = note;
    }
  });
};

const mergeBookMeta = (oldBook: BookMeta, newBook: BookMeta): BookMeta => {
  return {
    ...oldBook,
    ...newBook,
    settings: {
      ...oldBook.settings,
      ...newBook.settings,
      updates: dedupe([...(oldBook.settings?.updates || []), ...(newBook.settings?.updates || [])]),
    },
    notes: mergeNotes(oldBook.notes, newBook.notes),
    visibleAt: {
      ...oldBook.visibleAt,
      ...newBook.visibleAt,
    },
    minutesRead: {
      ...oldBook.minutesRead,
      ...newBook.minutesRead,
    },
  };
};

const writeLocalBook = (book: BookMeta) => {
  const books = getBooks();
  books[book.id] = book;
  updateBooksCache(books);
  console.debug('wrote local book', book);
};

export const updateBook = async (bookUpdate: BookMeta, important: boolean = false): Promise<BookMeta | undefined> => {
  const isOnline = useOnlineStore.getState().isOnline;
  const books = getBooks();
  const localBook = books[bookUpdate.id] || defaultBookMeta;
  const updatedBook = mergeBookMeta(localBook, bookUpdate);
  const currentTime = Date.now();
  const lastRemoteUpdateTimestamp = parseInt(localStorage.getItem('lastRemoteUpdateTimestamp') || '0', 10);
  const secondsSinceLastRemoteUpdate = Math.ceil((currentTime - lastRemoteUpdateTimestamp) / 1000);

  if (secondsSinceLastRemoteUpdate > 60 || important) {
    polishBook(updatedBook);
  }
  books[updatedBook.id] = updatedBook;
  updateBooksCache(books);

  if (secondsSinceLastRemoteUpdate < 60 && !important) {
    return updatedBook;
  }
  console.debug('updating book because ', important ? 'important' : 'secondsSinceLastRemoteUpdate');

  const currentUser = fetchUser();

  if (Object.keys(updatedBook).length > 0 && currentUser) {
    console.log('/api/update_book with: ', updatedBook);
    localStorage.setItem('lastRemoteUpdateTimestamp', currentTime.toString());
    if (!isOnline) {
      return updatedBook;
    }

    try {
      const response = await fetch('/api/update_book', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedBook),
      });

      if (response.status === 401) {
        localStorage.removeItem('currentUser');
        deleteCookie();
        fetchUser(true);
        return updatedBook;
      }

      const data = await response.json();
      if (!data.error) {
        console.debug('received data on updateBook', data);
      } else {
        console.error('data error on updateBook', data);
      }
    } catch (error) {
      console.error('failed on full update', updatedBook, error);
    }
    return updatedBook;
  }
};

/**
 * Merges two NotesGroup objects, giving priority to the primary group.
 *
 * @param secondary - The secondary NotesGroup to merge from.
 * @param primary - The primary NotesGroup to merge into, which takes precedence.
 * @returns A new NotesGroup containing merged notes from both inputs.
 *
 * @description
 * This function combines two NotesGroup objects, with the following behavior:
 * - All notes from the primary group are included in the result.
 * - Notes from the secondary group are added if they don't exist in the primary group.
 * - For notes that exist in both groups, the primary group's properties take precedence.
 * - Same behavior for the 'comments' property, which is merged from both groups.
 */
const mergeNotes = (secondary: NotesGroup, primary: NotesGroup): NotesGroup => {
  const primaryNotes: NotesGroup = { ...primary };

  for (const [key, secondaryNote] of Object.entries(secondary)) {
    if (primaryNotes[key]) {
      // If the note exists on primary, merge its properties
      const primaryNote = primaryNotes[key];
      primaryNotes[key] = {
        ...secondaryNote,
        ...primaryNote,
        comments: {
          ...secondaryNote.comments,
          ...primaryNote.comments,
        },
      };
    } else {
      // If the note doesn't exist on primary, add it
      primaryNotes[key] = secondaryNote;
    }
  }

  return primaryNotes;
};

const reportOpenBase = async (id: string): Promise<BookMeta | undefined> => {
  const isOnline = useOnlineStore.getState().isOnline;
  const user = fetchUser();
  if (!user) {
    console.log('No user found');
    return Promise.reject('No user found'); // or return Promise.resolve(null); if you prefer
  }

  const localBook = getBook(id);
  if (!localBook) {
    console.error('No book found with id', id);
    return Promise.reject('No book found');
    // return Promise.reject("No book found"); // or return Promise.resolve(null); if you prefer
  }

  if (!isOnline) {
    return localBook;
  }
  try {
    const response = await fetch('/api/open_book', {
      credentials: 'include', // Necessary to include cookies with the request
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Ensure you set the content type header
      },
      body: JSON.stringify(localBook),
    });
    let data = await response.json();
    if (!data.error) {
      console.log('received data on reportOpen', data);
      const remoteBook: BookMeta = data;

      // TODO: local notes should take precedence! but trying to fix that bug
      const mergedBook = mergeBookMeta(remoteBook, localBook);
      writeLocalBook(mergedBook);
      return mergedBook;
    } else {
      console.warn('data error on reportOpen', data);
      return;
      // return Promise.reject(data.error); // Reject the promise if there's an error
    }
  } catch (error) {
    console.error('Fetch error on reportOpen', error);
    // fetchUser(true);
    return;
    // return Promise.reject(error); // Reject the promise if the fetch fails
  }
};

export const upgrade = async () => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  const updateVersion = (version: number): number => {
    localStorage.setItem('version', version.toString());
    console.log('set version to', version);
    return version;
  };

  let version = getVersion();
  const books = getBooks();
  if (!books) {
    return version;
  }

  try {
    if (version < 1) {
      Object.keys(books).forEach((bookId) => {
        const book = books[bookId];

        // Remove 'updates' if it exists directly under the book
        if ('updates' in book) {
          delete book.updates;
        }

        // Process 'settings.updates' if it exists
        if (book.settings && book.settings.updates) {
          const validUpdates: [number, number][] = [];
          book.settings.updates.forEach((update) => {
            if (
              Array.isArray(update) &&
              update.length === 2 &&
              typeof update[0] === 'number' &&
              typeof update[1] === 'number'
            ) {
              // Check if the timestamp is in seconds (i.e., less than 10000000000)
              if (update[0] < 10000000000) {
                update[0] *= 1000; // Convert to milliseconds
              }
              validUpdates.push(update as [number, number]);
            }
          });
          books[bookId].settings.updates = validUpdates;
        }
      });
      version = updateVersion(1);
    }
    if (version < 5) {
      Object.keys(books).forEach((bookId) => {
        if (books[bookId].settings) {
          books[bookId].settings.margin = -1;
          books[bookId].settings.alignment = 'justified';
          books[bookId].settings.flow = 'paginated';
          books[bookId].settings.showNotes = false;
        }
        books[bookId].wordCount = undefined;
        books[bookId].chapterWordCount = undefined;
      });
      version = updateVersion(5);
    }
    if (version < 6) {
      Object.keys(books).forEach((bookId) => {
        const updates = books[bookId].settings.updates;
        books[bookId].timestamp = updates && updates.length ? updates[updates.length - 1][0] : 0;
      });
      version = updateVersion(6);
    }
    if (version < 8) {
      const user = getCurrentUser();
      if (user && user.prefs) {
        localStorage.setItem(
          'currentUser',
          JSON.stringify({
            ...user,
            prefs: {
              ...user.prefs,
              statusBarBoxes: defaultPrefs.statusBarBoxes,
            },
          })
        );
        version = updateVersion(8);
      } else {
        throw new Error('not logged in');
      }
    }
    if (version < 9) {
      for (const bookId of Object.keys(books)) {
        const book = books[bookId];
        try {
          const bookData = await getArrayBuffer(bookId);
          if (!bookData) {
            console.error(`Failed to get ArrayBuffer for book ${bookId}`);
            continue;
          }
          const epubBook = Epub(bookData);
          await epubBook.loaded.spine;
          const [totalWords, chapterWords] = await getTotalWordCount(epubBook);
          book.wordCount = totalWords;
          book.chapterWordCount = chapterWords;
          books[bookId] = book;
        } catch (error) {
          console.warn(`V9: Failed to update word counts for book ${bookId}`, error);
        }
      }
      version = updateVersion(9);
    }
    if (version < 10) {
      Object.keys(books).forEach((bookId) => {
        if (!books[bookId].visibleAt) {
          books[bookId].visibleAt = {};
        }
      });

      version = updateVersion(10);
    }
    if (version < 11) {
      const archivedBooksJson = localStorage.getItem('archived');
      if (archivedBooksJson) {
        const archivedBooks: BookCollection = JSON.parse(archivedBooksJson);
        Object.keys(archivedBooks).forEach((bookId) => {
          const book = archivedBooks[bookId];
          book.archived = true;
          if (!book.visibleAt) {
            book.visibleAt = {};
          }
          if (!books[bookId]) {
            books[bookId] = book;
            delete archivedBooks[bookId];
          }
        });
        if (Object.keys(archivedBooks).length) {
          localStorage.setItem('archived', JSON.stringify(archivedBooks));
          console.log('archived books remaining:', archivedBooks);
        } else {
          localStorage.removeItem('archived');
          console.log('all archived books merged', books);
        }
      }
      version = updateVersion(11);
    }
    if (version < 12) {
      console.log('resizing covers');
      const coverResize = await resizeAndReplaceCovers();
      console.log('cover resize result:', coverResize);
      version = updateVersion(12);
    }
    if (version < 15) {
      console.log('adjusting font size');
      Object.keys(books).forEach((bookId) => {
        if (books[bookId].settings && (books[bookId].settings.fontSize || 0) < 9) {
          books[bookId].settings.fontSize = 9;
        } else if (books[bookId].settings && (books[bookId].settings.fontSize || 0) > 30) {
          books[bookId].settings.fontSize = 30;
        }
      });
      version = updateVersion(15);
    }
    if (version < 16) {
      console.log('updating to version 16');
      Object.keys(books).forEach(async (bookId) => {
        if (books[bookId].archived) {
          books[bookId].location = 'archived';
        } else {
          books[bookId].location = 'active';
        }

        // Check if the epub file exists for this book
        const exists = await doesFileExist(bookId);
        if (!exists) {
          books[bookId].location = 'trash';
          console.log(`book ${bookId} marked as deleted due to missing file`);
        }
        delete books[bookId].archived;
      });

      version = updateVersion(16);
    }
    if (version < 17) {
      console.log('updating to version 17');
      Object.keys(books).forEach((bookId) => {
        if (books[bookId].notes) {
          Object.keys(books[bookId].notes).forEach((noteId) => {
            books[bookId].notes[noteId].comments = {};
          });
        }
      });
      version = updateVersion(17);
    }
    if (version < 18) {
      console.log('updating to version 18');

      for (const bookId of Object.keys(books)) {
        try {
          const bookData = await getArrayBuffer(bookId);
          if (!bookData) {
            console.error(`Failed to get ArrayBuffer for book ${bookId}`);
            continue;
          }
          const epubBook = Epub(bookData);
          const metadata = await epubBook.loaded.metadata;
          if (metadata) {
            const book = {
              ...books[bookId],
              description: metadata.description,
              publishedDate: metadata.pubdate,
              author: metadata.creator,
              modifiedDate: metadata.modified_date,
              publisher: metadata.publisher,
              title: metadata.title,
              languageCode: metadata.language,
              identifier: metadata.identifier,
            };
            books[bookId] = book;
            console.log(`updated metadata for id=${bookId}: ${book.title} by ${book.author}`);
          }
        } catch (error) {
          console.error(`Failed to update metadata for book ${bookId}`, error);
        }
      }
      version = updateVersion(18);
    }
    if (version < 19) {
      console.log('updating to version 19');
      try {
        const db = await getDB();
        const tx = db.transaction(dbConfig.objectStoreName, 'readwrite');
        const store = tx.objectStore(dbConfig.objectStoreName);
        const keys = await store.getAllKeys();

        for (const key of keys) {
          if (typeof key === 'string' && key.startsWith('locations-')) {
            await store.delete(key);
            console.log(`Deleted locations object: ${key}`);
          }
        }

        await tx.done;
        console.log('All location objects removed from IndexedDB');
      } catch (error) {
        console.error('Error removing location objects:', error);
      }
      version = updateVersion(19);
    }
    if (version < 23) {
      console.log('updating to version 23');

      Object.keys(books).forEach((bookId) => {
        books[bookId].wpm = undefined;
        books[bookId].wpmTimestamp = undefined;
      });

      version = updateVersion(23);
    }
    if (version < 24) {
      console.log('upgrading to version 24');
      Object.keys(books).forEach((bookId) => {
        if (books[bookId].settings && (books[bookId].settings.fontSize || 0) < 11) {
          books[bookId].settings.fontSize = 11;
        }
        if (books[bookId].settings && (books[bookId].settings.lineSpacing || 0) < 0.95) {
          books[bookId].settings.lineSpacing = 0.95;
        }
      });
      version = updateVersion(24);
    }
    // UPDATE STORE VERSION
  } catch (error) {
    console.error('Failed to update books at version', version);
  } finally {
    // write version and books
    localStorage.setItem('version', version.toString());
    if (books) {
      updateBooksCache(books);
    }
    return version;
  }
};

export const getVersion = (): number => {
  return parseInt(localStorage.getItem('version') || '0', 10);
};

const fetchFollowingBase = async () => {
  const isOnline = useOnlineStore.getState().isOnline;
  const user = fetchUser();
  if (!user) {
    return [];
  }
  if (!isOnline) {
    return [];
  }
  console.log('fetching following...');
  try {
    const response = await fetch('/api/get_following', {
      credentials: 'include',
    });
    const data = await response.json();
    if (!data.error) {
      localStorage.setItem('following', JSON.stringify(data));
      return data;
    } else {
      // Handle error or no data scenario
      // localStorage.removeItem("following");
      //   fetchUser(true);
      return [];
    }
  } catch (error) {
    console.error('Failed to fetch following', error);
    // fetchUser(true);
    return [];
  }
};

export const getDisplayName = async (email: string) => {
  let following: Person[] = JSON.parse(localStorage.getItem('following') ?? '[]');
  // Attempt to find the user in the already fetched list
  let user = following.find((e) => e.email === email);

  if (!user) {
    // Fetch following if the user wasn't found or the list was empty
    following = await fetchFollowing();
    user = following.find((e) => e.email === email);
  }

  return user ? user.given_name : 'Anonymous';
};

export const getPerson = (email: string): Person | null => {
  if (email === 'ai') {
    return aiUser;
  }
  let following: Person[] = JSON.parse(localStorage.getItem('following') ?? '[]');
  // Attempt to find the user in the already fetched list
  let user = following.find((e) => e.email === email);

  // if (!user) {
  //   // Fetch following if the user wasn't found or the list was empty
  //   following = await fetchFollowing();
  //   user = following.find((e) => e.email === email);
  // }

  if (user) {
    return user;
  } else {
    return null;
  }
};

// Function to load the EPUB file
const loadEpub = async (id: string): Promise<Book | null> => {
  const books = getBooks();
  const book = books[id];
  try {
    const bookData = await getArrayBuffer(id);
    if (!bookData) {
      console.error(`Failed to get ArrayBuffer for book ${id}`);
      return null;
    }
    const epubBook = Epub(bookData);
    await epubBook.ready;
    return epubBook;
  } catch (error) {
    console.error(`Failed to load Epub for book ${id}`, error);
    return null;
  }
};

export const cleanUpNotes = (book: BookMeta): boolean => {
  // Filter out deleted notes
  book.notes = Object.fromEntries(Object.entries(book.notes).filter(([_, note]) => !note.deleted));

  let changed = false;
  Object.values(book.notes).forEach((note: Note) => {
    if (!note.timestamp) {
      note.timestamp = new Date().toISOString();
      changed = true;
    }
    if (!note.id) {
      note.id = crypto.randomUUID();
      changed = true;
    }
    if (note.comment && (!note.comments || Object.keys(note.comments).length === 0)) {
      const id = crypto.randomUUID();
      note.comments = {
        [id]: {
          text: note.comment,
          owner: note.owner,
          timestamp: note.timestamp || new Date().toISOString(),
          id: id,
          reactions: {},
        },
      };
      changed = true;
    }
  });
  return changed;
};

export const getBook = (id: string): BookMeta | undefined => {
  const books = getBooks();
  let book = books[id];
  if (!book) {
    return;
  }
  if (!book.notes) {
    book.notes = {};
  } else if (cleanUpNotes(book)) {
    console.warn('cleanUpNotes changed book ', book);
    updateBook(book);
  }
  return book;
};

export const getUserId = (): string => {
  const user = getCurrentUser();
  return user?.email || '';
};

export const getCurrentUser = (): UserInfo | null => {
  const savedUser = localStorage.getItem('currentUser');
  if (savedUser) {
    const user = JSON.parse(savedUser);
    // TODO: check if expired or anything
    return user || null;
  }
  return null;
};

export const fetchUser = (force: boolean = false) => {
  const user = getCurrentUser();
  if (!force && user) {
    return user;
  }

  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }

  console.log('fetching...');
  // Fetch the current user's information from the backend
  fetch('/api/current_user', {
    credentials: 'include', // Necessary to include cookies with the request
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data.error) {
        localStorage.setItem('currentUser', JSON.stringify(data));
        return data; // Update your state with the user data
      } else {
        localStorage.removeItem('currentUser');
        deleteCookie();
        console.error('data error fetching user: ', data);
        return null; // No user logged in, or session expired
      }
    });
};

export const dedupe = (data: UpdateRecord[], field = 0): UpdateRecord[] => {
  const seen = new Set();
  return data.filter((item) => {
    if (seen.has(item[field])) {
      return false;
    }
    seen.add(item[field]);
    return true;
  });
};

const getUpdatesBase = async (id: string): Promise<UpdateRecord[]> => {
  const book = getBook(id);
  if (!book) {
    return [];
  }

  const user = fetchUser();
  if (!user) {
    console.log('No user found in getUpdatesBase');
    return book.settings?.updates || [];
  }

  const hash = book.hash;
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return [];
  }
  console.log('/api/get_updates', hash);
  try {
    const response = await fetch('/api/get_updates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ hash: hash }),
    });

    let data = await response.json();
    if (!data.error) {
      //   await updateBook({ ...book, settings: { updates: data } });
      //   writeLocalBook(mergeBookMeta({ ...book, settings: { updates: data } }, book));
      return data; // Successfully return the data
    } else {
      console.error('data error: ', data);
      return []; // Return empty array if there's an error in the data
    }
  } catch (error) {
    console.error('getUpdates error: ', error);
    return []; // Return empty array on fetch error
  }
};

export const logoutUser = () => {
  // Optional: Inform your backend to invalidate the session
  localStorage.removeItem('currentUser');
  deleteCookie();
  fetch('/api/logout', {
    credentials: 'include', // If you're using cookies to manage session
    // Additional fetch options as needed
  })
    .then((response) => {
      // Handle response
      if (response.ok) {
        // Clear user state/frontend session
        console.log('logged out okay');
      }
    })
    .catch((error) => {
      console.error('Logout error', error);
    });
};

export const saveLocations = (id: string, locations: any) => {
  saveObject(`locations-${id}`, locations);
  console.debug('saveLocations for ', id);
};

export const loadLocations = async (id: string) => {
  //   const locs = JSON.parse(localStorage.getItem("locations") || "{}");
  // if (Object.keys(locs).includes(id))
  const locs = await loadObject(`locations-${id}`);
  if (!locs) {
    console.log('no locations for ', id);
  }
  //   console.debug("loadLocations loc = ", locs);
  return locs;
};

/**
 * Updates the current user's information in local storage.
 *
 * @param {Partial<UserInfo>} user - Partial user information to update.
 * @throws {Error} Logs an error if no current user is found.
 * @see {@link mergePrefs}
 *
 * @description
 * This function retrieves the current user from local storage,
 * merges the new user information with the existing data,
 * and updates the local storage with the combined information.
 * It also merges the user preferences using the `mergePrefs` function.
 * If no current user is found, it logs an error and returns without making changes.
 */
export const updateUser = (user: Partial<UserInfo>, prefs?: Partial<Prefs>) => {
  const savedUser = getCurrentUser();
  if (!savedUser) {
    console.error('No user found in updateUser');
    return;
  }
  const newPrefs = mergePrefs(mergePrefs(savedUser.prefs, user.prefs), prefs);
  const newUser = {
    ...savedUser,
    ...user,
    prefs: newPrefs,
  };
  localStorage.setItem('currentUser', JSON.stringify(newUser));
};

/**
 * Merges user preferences with new preferences and default preferences.
 *
 * @param {Partial<Prefs>} userPrefs - The existing user preferences.
 * @param {Partial<Prefs>} newPrefs - The new preferences to be merged.
 * @returns {Prefs} A new Prefs object containing the merged preferences.
 *
 * @description
 * This function combines three sources of preferences:
 * 1. Default preferences (defaultPrefs)
 * 2. Existing user preferences (userPrefs)
 * 3. New preferences to be applied (newPrefs)
 *
 * It handles special merging for the statusBarBoxes array and the favorite object.
 * For statusBarBoxes, it combines all sources and removes duplicates based on position.
 * For favorite, it performs a shallow merge of all sources.
 */
export const mergePrefs = (userPrefs: Partial<Prefs> = {}, newPrefs: Partial<Prefs> = {}): Prefs => {
  const isArray = (value: any): value is any[] => Array.isArray(value);

  return {
    ...defaultPrefs,
    ...userPrefs,
    ...newPrefs,
    statusBarBoxes: Array.from(
      [
        ...defaultStatusBarBoxes,
        ...(isArray(userPrefs?.statusBarBoxes) ? userPrefs.statusBarBoxes : []),
        ...(isArray(newPrefs?.statusBarBoxes) ? newPrefs.statusBarBoxes : []),
      ]
        .reduce((acc, item) => {
          acc.set(item.position, item);
          return acc;
        }, new Map<number, StatusBarBoxInfo>())
        .values()
    ),
    favorite: {
      ...defaultFavorite,
      ...(userPrefs?.favorite || {}),
      ...(newPrefs?.favorite || {}),
    },
  };
};

export const getPrefs = (): Prefs => {
  const userJson = fetchUser();
  return mergePrefs(userJson?.prefs);
};

/**
 * Pushes user preferences to the server.
 *
 * @async
 * @returns {Promise<any>} A promise that resolves when the preferences are successfully updated, or rejects with an error.
 *
 * @description
 * This function merges the provided partial preferences with the existing user preferences,
 * then sends the updated preferences to the server. It handles various error cases,
 * including no user found, server errors, and network errors.
 */
export const pushPrefs = async (): Promise<any> => {
  const user = fetchUser();
  if (!user) {
    console.log('No user found in update_prefs');
    return Promise.reject('No user found');
  }
  const prefs = getPrefs();
  console.debug('pushPrefs', prefs);

  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  try {
    const response = await fetch('/api/update_prefs', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prefs: prefs,
      }),
    });
    const data = await response.json();
    if (!data.error) {
      console.log('pushed new prefs: ', prefs);
      return;
    } else {
      console.error('data error on update_prefs', data);
      return Promise.reject(data.error);
    }
  } catch (error) {
    console.error('Fetch error on update_prefs', error);
    fetchUser(true);
    return Promise.reject(error);
  }
};

const getStreaksBase = async (
  users: string[]
): Promise<{ [id: string]: { days: number; today: boolean } } | undefined> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  try {
    const response = await fetch('/api/get_streaks', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(users),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch streaks');
    }
    const data = await response.json();
    console.log('fetched streaks:', data);
    return data;
  } catch (error) {
    useOnlineStore.setState({ isOnline: false });
    console.error('Error fetching streaks:', error);
    throw error;
  }
};

const getBookProgressBase = async (hashes: string[]): Promise<any> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  const startTime = Date.now();
  try {
    const response = await fetch('/api/get_progress', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(hashes),
    });
    console.log(`getBookProgressBase fetch took ${Date.now() - startTime}ms`);

    if (!response.ok) {
      throw new Error('Failed to fetch book progress');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching book progress:', error);
    throw error;
  }
};

export const getBookData = async (book: BookMeta): Promise<any> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  try {
    const response = await fetch('/api/get_book', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(book),
    });
    if (!response.ok) {
      return {};
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching book progress:', error);
    return {};
  }
};

const median = (values: number[], midpoint: number = 0.5): number => {
  if (values.length === 0) return 0;
  values.sort((a, b) => a - b);
  const mid = Math.round(values.length * midpoint);
  return values[mid];
};

const convertBPStoWPM = (booksPerSecond: number[], wordCount: number): number => {
  const wordsPerMinute = wordCount * median(booksPerSecond, 0.5) * 60;
  return Math.round(wordsPerMinute);
};

const calculateBPS = (
  data: UpdateRecord[],
  lowerBoundTimestamp = 0,
  lowerBoundSeconds = 10,
  upperBoundSeconds = 4 * 60,
  upperBoundPropDiff = 0.01
): number[] => {
  let recentData = data;
  if (lowerBoundTimestamp) {
    recentData = data.filter(([time]) => time >= lowerBoundTimestamp);
  }
  if (recentData.length == 0) {
    return [];
  }

  recentData.sort((a, b) => a[0] - b[0]);

  const booksPerSecond: number[] = [];
  const seenProps = new Set<number>();

  for (let i = 1; i < recentData.length; i++) {
    const [prevTime, prevProp] = recentData[i - 1];
    while (i < recentData.length - 1 && seenProps.has(recentData[i][1])) {
      i++;
    }
    seenProps.add(recentData[i][1]);
    const [currentTime, currentProp] = recentData[i];

    const timeDiff = (currentTime - prevTime) / 1000;
    const propDiff = currentProp - prevProp;
    if (lowerBoundSeconds < timeDiff && timeDiff < upperBoundSeconds && 0 < propDiff && propDiff < upperBoundPropDiff) {
      booksPerSecond.push(propDiff / timeDiff);
    }
  }

  if (booksPerSecond.length < 20) {
    console.log('booksPerSecond:', booksPerSecond);
  }
  return booksPerSecond;
};

export const calculateWPM = (
  data: UpdateRecord[],
  wordCount: number,
  hoursAgo?: number,
  recentDataPoints: number = 10,
  savedTotalWPM?: number
): number | undefined => {
  if (savedTotalWPM && !hoursAgo) {
    return savedTotalWPM;
  }

  if (data.length < 5) {
    return undefined;
  }

  let totalWPM;
  if (!savedTotalWPM) {
    const totalBooksPerSecond = calculateBPS(data);
    totalWPM = convertBPStoWPM(totalBooksPerSecond, wordCount);
  } else {
    totalWPM = savedTotalWPM;
  }

  if (!hoursAgo) {
    return totalWPM;
  }

  const recentBooksPerSecond = calculateBPS(data, Date.now() - 1000 * 60 * 60 * hoursAgo);

  const recentWPM = convertBPStoWPM(recentBooksPerSecond, wordCount);
  console.log('recentWPM:', recentWPM, 'totalWPM:', totalWPM);
  const weightedWPM = Math.ceil(
    (totalWPM * Math.max(recentDataPoints - recentBooksPerSecond.length, 0) +
      recentWPM * Math.min(recentBooksPerSecond.length, recentDataPoints)) /
      recentDataPoints
  );
  return weightedWPM;
};

export const getTotalWPM = async (book: BookMeta): Promise<number | undefined> => {
  if (
    book.wpm &&
    book.settings.updates &&
    book.settings.updates.length > 0 &&
    book.wpmTimestamp &&
    book.settings.updates[book.settings.updates.length - 1][0] - book.wpmTimestamp < 1000 * 30
  ) {
    return book.wpm;
  } else if (book.wordCount) {
    const wpm = calculateWPM(book.settings.updates || [], book.wordCount, 0);
    return wpm;
  } else {
    return undefined;
  }
};

const countWords = (text: string): number => {
  return Math.ceil(text.length / 5.5);
};

export const getTotalWordCount = async (book: Book): Promise<[number, number[]]> => {
  let totalWords = 0;
  try {
    await book.ready;
    const chapterCountsWithIndex: [number, number][] = [];
    for (const entry of book.packaging.spine as any[]) {
      const section: any = await book.load(entry.href);
      if (section) {
        const text = section.body.textContent || '';
        const amount = countWords(text);

        chapterCountsWithIndex.push([entry.index, amount]);
        totalWords += amount;
      }
    }
    // Sort the chapter counts based on the index and extract only the counts
    const chapterCounts = chapterCountsWithIndex.sort((a, b) => a[0] - b[0]).map(([_, count]) => count);
    console.log('Total Word Count:', Math.round(totalWords));
    return [Math.round(totalWords), chapterCounts];
  } catch (error) {
    console.error('Error processing the ePub:', error);
    return [0, []];
  }
};

/**
 * Given a book, return an array of strings where each string is a chapter of the book.
 * This function is asynchronous to handle the loading of book sections.
 */
export const getChapters = async (book: Book): Promise<string[]> => {
  const chapters: string[] = [];
  try {
    await book.ready;
    for (const entry of book.packaging.spine as any[]) {
      const section: any = await book.load(entry.href);
      if (section) {
        const chapterText = section.body.textContent || '';
        chapters.push(chapterText);
      }
    }
  } catch (error) {
    console.error('Error loading chapters:', error);
  }
  return chapters;
};

export const pullBookRevs = async () => {
  try {
    const response = await fetch('/api/pull_revs', {
      credentials: 'include',
      method: 'GET',
    });

    if (response.ok) {
      const revs = await response.json();
      const books = getBooks();
      Object.keys(revs).forEach((id) => {
        if (books[id]) {
          books[id]._rev = revs[id];
        } else {
          console.warn(`Book with id ${id} not found in local storage`);
        }
      });
      updateBooksCache(books);
    } else {
      console.error('Failed to update book revs', response.status);
    }
  } catch (error) {
    console.error('Error updating book revs', error);
  }
};

export const scanBookForContext = async (
  book: Book,
  searchTerm: string,
  progress: number,
  before: number = 60,
  after: number = 90,
  maxResults: number = 20
): Promise<string[]> => {
  const results: string[] = [];
  try {
    await book.ready;
    const chapters = await getChapters(book);
    const wholeBook = chapters.join('\n\n');
    const bookToDate = wholeBook.slice(0, Math.ceil(progress * wholeBook.length));
    const regex = new RegExp(`.{0,${before}}${searchTerm}.{0,${after}}`, 'g');

    let match;
    while ((match = regex.exec(bookToDate)) !== null) {
      results.push(match[0]);
    }

    if (results.length > maxResults) {
      const first = results[0];
      const last = results[results.length - 1];
      const middleResults = results.slice(1, -1);

      const selectedMiddleResults = middleResults.sort(() => 0.5 - Math.random()).slice(0, maxResults - 2);

      return [first, ...selectedMiddleResults, last];
    }

    return results;
  } catch (error) {
    console.error('Error searching in book:', error);
    return [];
  }
};

export const uploadLog = async (logMessages: string[], clearLog: () => void) => {
  if (logMessages.length === 0) {
    console.log('nothing in log to upload');
    return;
  }
  console.log('uploading log...');
  try {
    const response = await fetch('/api/upload_log', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ logs: logMessages }),
    });

    if (response.ok) {
      console.log('Log successfully sent to the server, clearing');
      clearLog();
      return;
    } else {
      console.error('Failed to send logs', response.status);
    }
  } catch (error) {
    console.error('Error sending logs to server', error);
  }
};

// Add this new function to books.ts

/**
 * Handles the back navigation functionality for the e-book reader.
 *
 * @param updates - An array of UpdateRecord objects containing timestamp and progress information.
 * @param currentUpdate - The current UpdateRecord to start the back navigation from.
 * @param setSnackbarMessage - A function to set a message in the snackbar UI component.
 * @param goToPercentage - A function to navigate to a specific percentage in the book.
 * @param setUsedBack - A function to set whether updates are allowed.
 * @param historyRef - A mutable ref object to store the navigation history timestamp.
 * @returns The new timestamp after navigating back.
 */
export const handleBackNavigation = (
  updates: UpdateRecord[],
  currentUpdate: UpdateRecord,
  setSnackbarMessage: (message: SnackbarMessage) => void,
  goToPercentage: (progress: number) => void,
  setUsedBack: (usedBack: boolean) => void,
  historyRef: React.MutableRefObject<UpdateRecord | undefined>
): number => {
  let timestamp = currentUpdate[0];
  const startTimestamp = timestamp;
  let progress = currentUpdate[1];
  while (progress === -1 || progress === currentUpdate[1]) {
    [timestamp, progress] = getBack(updates, [timestamp, progress]);
  }
  const formattedDate = dayjs.utc(timestamp).local().format('YYYY-MM-DD HH:mm');
  setSnackbarMessage({ text: `Skipped back to ${formattedDate} at ${(100 * progress).toFixed(1)}%`, duration: 3000 });
  console.debug('back to', timestamp, progress);
  setUsedBack(true);
  historyRef.current = [timestamp, progress];
  goToPercentage(progress);
  return timestamp;
};

/**
 * Retrieves the most recent update record that occurred before a specified time threshold.
 *
 * @param updates - An array of UpdateRecord objects, each containing a timestamp and a value.
 * @param lastTimestamp - The reference timestamp to compare against (default: current timestamp).
 * @param minSeconds - The minimum number of seconds to look back from the lastTimestamp (default: 60 seconds).
 * @returns The most recent UpdateRecord that occurred before the specified time threshold, or [0,0] if no updates exist.
 */
export const getBack = (
  updates: UpdateRecord[],
  lastUpdate: UpdateRecord = [Date.now(), -1],
  minSeconds: number = 60,
  minProgress: number = 0.001
): UpdateRecord => {
  if (updates.length === 0) {
    return [0, 0];
  }
  const [lastTimestamp, lastProgress] = lastUpdate;
  const index = updates.findLastIndex(
    (update) => update[0] < lastTimestamp - minSeconds * 1000 && Math.abs(update[1] - lastProgress) > minProgress
  );
  if (index === -1) {
    return updates[updates.length - 1];
  }
  return updates[index];
};

export const guessCurrent = (updates?: UpdateRecord[], progress?: number): number => {
  console.log(updates);
  if (!updates || updates.length === 0 || !progress) {
    return 0;
  }

  updates.sort((a, b) => a[0] - b[0]);
  return (
    updates.findLast(
      (update, i) =>
        update[1] !== progress &&
        i > 1 &&
        updates[i][0] - updates[i - 1][0] > 10000 &&
        updates[i - 1][0] - updates[i - 2][0] > 10000 &&
        updates[i][1] > updates[i - 1][1] &&
        updates[i - 1][1] > updates[i - 2][1]
    )?.[1] ||
    updates.findLast((update, i) => update[1] !== progress)?.[1] ||
    progress
  );
};

/**
 * Adjusts the fullscreen class on the document body and the .epub-container element
 * based on the current fullscreen state.
 */
export const adjustFullscreenClass = () => {
  const doc = document.querySelector('.epub-container');
  const isFullscreen = checkIfFullscreen();
  if (doc) {
    console.log('setting fullscreen to ', isFullscreen);
    if (isFullscreen) {
      document.body.classList.add('fullscreen');
      doc.classList.add('fullscreen');
    } else {
      document.body.classList.remove('fullscreen');
      doc.classList.remove('fullscreen');
    }
  }
};

/**
 * Checks if the document is currently in fullscreen mode.
 *
 * @returns {boolean} - Returns true if the document is in fullscreen mode, otherwise false.
 */
export const checkIfFullscreen = (): boolean => {
  return document.fullscreenElement !== null;
};

/**
 * Toggles the fullscreen mode based on the desired state.
 *
 * @param {boolean | undefined} wantFullscreen - The desired fullscreen state. If undefined, the function will return without making any changes.
 */
export const changeFullscreen = (wantFullscreen: boolean | undefined) => {
  if ((isIOS && isMobileOnly) || isDesktop) {
    console.info('ios mobile or desktop, not changing fullscreen');
    return;
  }
  if (wantFullscreen === undefined) {
    return;
  }

  const isFullscreen = checkIfFullscreen();

  try {
    if (wantFullscreen && !isFullscreen) {
      document.documentElement.requestFullscreen({ navigationUI: 'hide' });
      return true;
    } else if (!wantFullscreen && isFullscreen) {
      document.exitFullscreen();
      return true;
    }
  } catch (error) {
    if (error instanceof DOMException && error.name === 'NotAllowedError') {
      console.info('Fullscreen request was denied. This may be due to lack of user interaction.');
      return;
    } else {
      console.error('Fullscreen error:', error);
      return;
    }
  }
  return false;
};

export const durationFormat = (duration: number): string => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (hours > 100) {
    return `${hours.toFixed(0)}h`;
  } else if (hours > 10) {
    return `${hours.toFixed(1)}h`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
};

const updateMinutesReadInBook = async (oldBook: BookMeta) => {
  const updates = oldBook.settings.updates || [];
  const times = await getIntervalCounts(updates);
  const book = getBooks()[oldBook.id];
  book.minutesRead = times;
  book.lastUpdated = Date.now();
  console.debug('minutesPerDay: ', times, 'for ', book.title);
  updateBook(book);
  return book;
};

/**
 * Retrieves the total number of minutes read for a specific book.
 *
 * @param {string} id - The unique identifier of the book.
 * @returns {Promise<number>} A promise that resolves to the total number of minutes read.
 */
export const getMinutesReadInBook = async (id: string): Promise<number> => {
  // Retrieve the book from the local storage
  let book = getBooks()[id];

  // If the book doesn't exist, return 0 minutes
  if (!book) {
    return 0;
  }

  // Check if the book's reading time needs to be updated
  // This happens if the book has never been updated or if it has been more than a minute since the last update
  if (!book.lastUpdated || (book.timestamp && book.timestamp > book.lastUpdated + 60 * 1000)) {
    // Update the book's reading time
    console.debug('updating reading minutes for ', book.title, 'id = ', book.id);
    book = await updateMinutesReadInBook(book);
  }

  // Calculate the total minutes read
  // If minutesRead is undefined, use an empty object as fallback
  const total = Object.values(book.minutesRead || {}).reduce((acc, minutes) => acc + minutes, 0);

  // Return the total minutes read
  return total;
};

export const findFirstSignificantChapter = async (book: Book): Promise<string> => {
  try {
    await book.ready;
    const chapters = book.packaging.spine as any[];
    let longestChapter = { href: '', length: 0 };

    for (const entry of chapters) {
      const section: any = await book.load(entry.href);
      if (section) {
        const chapterText = section.body.textContent || '';
        if (chapterText.length > longestChapter.length && chapterText.length > 1000) {
          longestChapter = { href: entry.href, length: chapterText.length };
        }
      }
    }

    return longestChapter.href || (chapters[0] && chapters[0].href) || '';
  } catch (error) {
    console.error('Error finding first significant chapter:', error);
    return '';
  }
};

export const calculateSectionScaleFactor = async (book: Book, href: string): Promise<number> => {
  try {
    await book.ready;
    const section: any = await book.load(href);
    if (!section) {
      return 1;
    }

    // Create a temporary, hidden iframe to render content
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.left = '-9999px';
    iframe.style.width = '1000px'; // Set a fixed width to ensure consistent rendering
    document.body.appendChild(iframe);

    if (iframe.contentDocument) {
      iframe.contentDocument.body.innerHTML = section.body.innerHTML;

      const textSelectors = getAllTextSelectors(iframe.contentDocument);
      const mostCommonSelector = findMostCommonTextSelector(iframe.contentDocument, textSelectors);

      const elements = iframe.contentDocument.querySelectorAll(mostCommonSelector);
      let totalFontSize = 0;
      let totalElements = 0;

      elements.forEach((el: Element) => {
        const fontSize = parseFloat(window.getComputedStyle(el).fontSize);
        if (!isNaN(fontSize)) {
          totalFontSize += fontSize;
          totalElements++;
        }
      });

      // Remove the temporary iframe
      document.body.removeChild(iframe);

      const averageFontSize = totalElements > 0 ? totalFontSize / totalElements : 16;
      return averageFontSize / 16; // Assuming 16px as the base font size
    }

    return 1;
  } catch (error) {
    console.error('Error calculating section scale factor:', error);
    return 1;
  }
};

const getAllTextSelectors = (doc: Document): string[] => {
  const elements = doc.querySelectorAll('p, h1, h2, h3, h4, h5, h6, span, div');
  const selectorSet = new Set<string>();

  elements.forEach((el) => {
    const tagName = el.tagName.toLowerCase();
    if (el.className) {
      selectorSet.add(
        `${tagName}${el.className
          .split(' ')
          .sort()
          .map((c) => `.${c}`)
          .join('')}`
      );
    } else {
      selectorSet.add(tagName);
    }
  });
  return Array.from(selectorSet);
};

const findMostCommonTextSelector = (doc: Document, selectors: string[]): string => {
  let maxCount = 0;
  let mostCommonSelector = 'p';

  selectors.forEach((selector) => {
    const count = doc.querySelectorAll(selector).length;
    if (count > maxCount) {
      maxCount = count;
      mostCommonSelector = selector;
    }
  });

  return mostCommonSelector;
};

/**
 * Finds the nth-highest non-unique percentage value from a list of update records.
 *
 * @param updates - An array of UpdateRecord objects containing timestamp and percentage information.
 * @returns The nth-highest non-unique percentage value, or undefined if there are fewer than n unique values.
 */
export const getNthHighestPercentage = (updates: UpdateRecord[], n: number = 3): number | undefined => {
  if (updates.length < n) {
    return undefined;
  }

  // Sort the updates by percentage in descending order
  const sortedPercentages = updates.map(([_, percentage]) => percentage).sort((a, b) => b - a);

  // Find the nth-highest non-unique value
  let count = 0;
  let lastValue = Infinity;

  for (const percentage of sortedPercentages) {
    if (percentage !== lastValue) {
      count++;
      if (count === n) {
        return percentage;
      }
      lastValue = percentage;
    }
  }

  // If there are fewer than n unique values, return undefined
  return undefined;
};

export const calculateEngagement = async (book: BookMeta): Promise<number> => {
  if (book.wordCount && book.settings.updates && book.settings.updates.length > 5 && book.settings.progress) {
    // const current = guessCurrent(book.settings.updates);
    // const current = book.settings.progress;
    const current = getNthHighestPercentage(book.settings.updates, 3);
    if (!current) {
      return 0;
    }
    const wordsRead = book.wordCount * current;
    const wpm = await getTotalWPM(book);
    if (!wpm) {
      return 0;
    }
    const minutesRead = await getMinutesReadInBook(book.id);
    const minutesProjected = wpm && book.wordCount && wordsRead / wpm;
    return (minutesRead && minutesProjected && minutesProjected / minutesRead) || 0;
  }
  return 0;
};

const getIntervalCounts = async (updates: UpdateRecord[] | undefined): Promise<Record<number, number>> => {
  if (!updates) {
    return {};
  }

  const minutesPerDay: Record<number, number> = {};

  updates
    .sort((a, b) => a[0] - b[0])
    .forEach(([timestamp, _], i, arr) => {
      if (i === 0) return; // Skip the first element

      // TODO: proper local time
      const offset = 1000 * 60 * 60 * 4;
      const prevTimestamp = arr[i - 1][0];
      const day = Math.floor((timestamp - offset) / (24 * 60 * 60 * 1000));
      const prevDay = Math.floor((prevTimestamp - offset) / (24 * 60 * 60 * 1000));

      if (day !== prevDay) {
        // If the day has changed, ensure the previous day is counted
        minutesPerDay[prevDay] =
          (minutesPerDay[prevDay] || 0) +
          countMinutesInInterval(arr, prevDay * 24 * 60 * 60 * 1000 + offset, day * 24 * 60 * 60 * 1000 + offset);
      }

      // Count the current day

      const lastMidnight = Math.floor((Date.now() - offset) / (24 * 60 * 60 * 1000)) * 1000 * 60 * 60 * 24 + offset;
      minutesPerDay[Math.floor((Date.now() - offset) / (24 * 60 * 60 * 1000))] = countMinutesInInterval(
        arr,
        lastMidnight,
        lastMidnight + 24 * 60 * 60 * 1000
      );
    });

  return minutesPerDay;
};

const countMinutesInInterval = (updates: UpdateRecord[] | undefined, start: number = 0, end: number = Infinity) => {
  if (!updates) {
    return 0;
  }
  const updatesInInterval = updates
    ?.filter(([timestamp, _]) => timestamp >= start && timestamp <= end)
    .sort((a, b) => a[0] - b[0]);
  // get list of differences between consecutive updates in both time and prop
  if (updatesInInterval.length < 2) {
    return 0;
  }
  let diffs = updatesInInterval.slice(1).map((current, i) => {
    const prev = updatesInInterval[i];
    return [current[0] - prev[0], current[1] - prev[1]] as UpdateRecord;
  });
  diffs = diffs.filter(([timeDiff, progDiff]) => timeDiff < 300000 && timeDiff > 0 && Math.abs(progDiff) < 1);
  const totalTime = diffs.reduce((acc, [timeDiff, _]) => acc + timeDiff, 0);
  return Math.ceil(totalTime / (60 * 1000));
};

const getStartEndOfDay = (daysAgo: number) => {
  const startOfDay = dayjs.utc().local().subtract(daysAgo, 'day').startOf('day').valueOf();
  const endOfDay = dayjs.utc().local().subtract(daysAgo, 'day').endOf('day').valueOf();
  return { startOfDay, endOfDay };
};
const getDayAbbreviation = (date: dayjs.Dayjs) => {
  const day = date.format('dd'); // 'dd' returns the two-letter day abbreviation
  return day.charAt(0).toUpperCase();
};

export const aggregateWeeklyTime = async (days: number = 7, label: 'dayOfWeek' | 'dayOfMonth' = 'dayOfWeek') => {
  //   const books = { ...getArchivedBooks(), ...getBooks() };
  const books = getBooks();

  const totalTimePerDay = Array.from({ length: days }, (_, i) => {
    const date = dayjs().subtract(i, 'day');
    const { startOfDay, endOfDay } = getStartEndOfDay(i);
    const totalTimeForDay = Object.values(books).reduce((acc, book) => {
      return acc + countMinutesInInterval(book.settings.updates || [], startOfDay, endOfDay);
    }, 0);
    let day = '';
    if (label === 'dayOfWeek') {
      day = getDayAbbreviation(date);
    } else {
      day = date.format('D');
    }
    return { label: day, value: totalTimeForDay };
  }).reverse();
  return totalTimePerDay;
};

export const replaceBookFile = async (id: string, file: File) => {
  console.log('replacing file for book with id: ', id, file);
  return new Promise((resolve, reject) => {
    const oldBook = getBooks()[id];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result;
      if (arrayBuffer && typeof arrayBuffer !== 'string') {
        const hash = await calculateFileHashFromArrayBuffer(arrayBuffer);
        try {
          const book = Epub(arrayBuffer);
          const metadata = await book.loaded.metadata;
          const title = metadata.title;
          const author = metadata.creator;
          let coverUrl = '';
          try {
            coverUrl = (await book.coverUrl()) ?? '';
          } catch (coverError) {
            console.error('Error extracting cover:', coverError);
          }
          const coverResponse = await fetch(coverUrl);
          const coverBlob = await coverResponse.blob();

          const saveFileResult = saveFile(id, file);
          const saveFileCoverResult = saveFile(`cover-${id}`, coverBlob);
          const deleteResult = deleteFile(`locations-${id}`, 'ePubObjects');
          console.debug('delete locations result: ', deleteResult);
          const [words, chapterWords] = await getTotalWordCount(book);
          console.log('total word count:', words);
          const newBook = {
            ...oldBook,
            title: title ?? file.name.replace(/\.[^/.]+$/, ''),
            hash: hash,
            timestamp: Date.now(),
            wordCount: words,
            languageCode: metadata.language,
            chapterWordCount: chapterWords,
            author: author,
            pubDate: metadata.pubdate,
            description: metadata.description,
          };
          const bookData = (await getBookData(newBook)) as BookMeta;
          console.debug('addBookFromFile, bookData = ', bookData, ' newBook = ', newBook);

          const mergedBook = mergeBookMeta(mergeBookMeta(defaultBookMeta, bookData), newBook);
          console.info('mergedBook = ', mergedBook);
          const updateBookResult = updateBook(mergedBook);
          await Promise.all([saveFileResult, saveFileCoverResult, updateBookResult]);
          resolve(title);
        } catch (error) {
          console.log('Error adding book:', error);
          resolve(''); // Resolve with an empty string on error to indicate failure
        }
      } else {
        resolve(''); // Resolve with an empty string if arrayBuffer is not valid
      }
    };

    reader.onerror = (error) => {
      console.log('Error reading file:', error);
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const createThumbnail = async (file: File | Blob): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target?.result as string;
    };

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (ctx) {
        canvas.width = 320;
        canvas.height = 480;
        ctx.drawImage(img, 0, 0, 320, 480);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Thumbnail creation failed'));
          }
        }, 'image/jpeg');
      } else {
        reject(new Error('Canvas context not available'));
      }
    };

    img.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
};

export const deleteBook = async (id: string): Promise<BookCollection> => {
  const books = getBooks();
  if (id && books[id]) {
    console.log('deleting book with id: ', id);
    const bookToDelete = books[id];
    if (bookToDelete.id) {
      deleteFile(bookToDelete.id);
      deleteFile(`cover-${bookToDelete.id}`);
      deleteFile(`locations-${bookToDelete.id}`, 'ePubObjects');
    }
    const bookDeleted = { ...bookToDelete, location: 'trash', archived: undefined };
    await updateBook(bookDeleted, true);
    return { ...books, [id]: bookDeleted };
  }
  return books;
};

export const addBookFromFile = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result;
      if (arrayBuffer && typeof arrayBuffer !== 'string') {
        const hash = await calculateFileHashFromArrayBuffer(arrayBuffer);
        const id = crypto.randomUUID();
        try {
          const book = Epub(arrayBuffer);
          const metadata = await book.loaded.metadata;
          const title = metadata.title;
          const author = metadata.creator;
          let coverUrl = '';
          try {
            coverUrl = (await book.coverUrl()) ?? '';
          } catch (coverError) {
            console.error('Error extracting cover:', coverError);
          }
          const coverResponse = await fetch(coverUrl);
          const coverBlob = await coverResponse.blob();
          const thumbBlob = await createThumbnail(coverBlob);
          //   await saveFile(`thumbnail-${file.name}`, coverBlob);

          const [words, chapterWords] = await getTotalWordCount(book);
          console.log('total word count:', words);
          const favorite = getPrefs()?.favorite;
          const newBook: BookMeta = {
            id,
            title: title ?? file.name.replace(/\.[^/.]+$/, ''),
            settings: { ...defaultBookSettings, ...favorite },
            createdAt: Date.now(),
            notes: {},
            hash: hash,
            timestamp: Date.now(),
            wordCount: words,
            languageCode: metadata.language,
            chapterWordCount: chapterWords,
            visibleAt: {},
            author: author,
            publishedDate: metadata.pubdate,
            description: metadata.description,
          };
          const bookData = (await getBookData(newBook)) as BookMeta;
          console.log('addBookFromFile, bookData = ', bookData, ' newBook = ', newBook);
          const mergedBook = {
            ...mergeBookMeta(defaultBookMeta, mergeBookMeta(newBook, bookData)),
            location: 'active',
            timestamp: Date.now(),
            archived: undefined,
          };
          const updateBookResult = updateBook(mergedBook);
          const saveFileResult = saveFile(mergedBook.id, file);
          const saveFileCoverResult = saveFile(`cover-${mergedBook.id}`, thumbBlob);
          await Promise.all([saveFileResult, saveFileCoverResult, updateBookResult]);

          resolve(title);
        } catch (error) {
          console.log('Error adding book:', error);
          resolve(''); // Resolve with an empty string on error to indicate failure
        }
      } else {
        resolve(''); // Resolve with an empty string if arrayBuffer is not valid
      }
    };

    reader.onerror = (error) => {
      console.log('Error reading file:', error);
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

/**
 * Refreshes the annotations by updating them with the provided notes.
 *
 * @param {Annotations} annotations - The annotations object to update.
 * @param {NotesGroup} notes - The group of notes containing the updated annotation details.
 */
export const refreshAnnotations = async (
  annotations: Annotations,
  notes: NotesGroup,
  bookMeta: BookMeta,
  iframeDoc?: Document
) => {
  const encounteredRanges = new Set<string>();
  Object.values(notes)
    .filter((note) => !note.deleted)
    .forEach((note) => {
      if (encounteredRanges.has(note.cfiRange)) {
        console.error('Duplicate cfiRange encountered: note = ', note);
      } else {
        encounteredRanges.add(note.cfiRange);
      }
      updateAnnotation(annotations, note, bookMeta, undefined);
    });
  console.info('refreshAnnotations: updated ', Object.keys(notes).length, ' annotations');
};

/**
 * Updates an annotation in the given annotations object.
 *
 * @param {Annotations} annotations - The annotations object to update.
 * @param {Note} note - The note containing the updated annotation details.
 */
export const updateAnnotation = async (
  annotations: Annotations,
  note: Note,
  bookMeta: BookMeta | undefined,
  iframeDoc: Document | undefined
) => {
  annotations.remove(note.cfiRange, 'highlight');
  const user = getCurrentUser();
  const noteStatus = getNoteStatus(note.id);
  if (noteStatus && noteStatus.hidden && note && note.owner === 'ai') {
    noteStatus.hidden = false;
  }
  if (noteStatus?.hidden) {
    logger.info('note is already hidden, skipping update for note with id: ', note.id);
    return;
  }
  const userCfi = bookMeta?.settings.location || '';
  const noteCfi = EpubCFI.prototype.parse(note.cfiRange);
  const userProgress = bookMeta?.settings.progress || 0;
  let isPastCurrentPoint = false;
  try {
    isPastCurrentPoint = EpubCFI.prototype.compare(noteCfi, userCfi) > 0;
  } catch (error) {
    logger.warn('Error comparing cfi ranges: ', error, 'note = ', note, 'userCfi = ', userCfi, 'noteCfi = ', noteCfi);
    if (userProgress && note.progress && note.progress > userProgress) {
      isPastCurrentPoint = true;
    } else {
      logger.error('Error comparing note progress as fallback: ', error);
      isPastCurrentPoint = false;
    }
  }

  // Check if "#show" appears in any of the comments
  const hasShowTag = Object.values(note.comments || {}).some((comment) => comment.text.toLowerCase().includes('#show'));

  const isHidden =
    !hasShowTag &&
    user &&
    note.owner &&
    note.owner !== 'ai' &&
    !noteStatus?.read &&
    isPastCurrentPoint &&
    note.owner !== user?.email;

  // TODO: not sure if the undefined status can be used this way, might have to explicitly save reveal status
  if (isHidden && noteStatus?.hidden === undefined) {
    logger.info('hiding note with id: ', note.id);
    setNoteStatus(note.id, { read: false, hidden: true });
    return;
  }
  annotations.add(
    'highlight',
    note.cfiRange,
    note,
    undefined,
    note.comments && Object.keys(note.comments).length > 0
      ? getNoteStatus(note.id)?.read
        ? 'wr-hlc'
        : 'wr-hlcu'
      : 'wr-hl',
    {
      stroke: note.color,
      fill: note.color,
      'fill-opacity': '0.3',
    }
  );

  applyReactions(note, bookMeta?.settings.fontSize);
};

const createSVG = (name: string) => {
  return document.createElementNS('http://www.w3.org/2000/svg', name);
};

const applyReactions = async (note: Note, fontSize?: number) => {
  const emojiSize = !fontSize || fontSize === defaultBookSettings.fontSize ? 16 : fontSize;
  if (_.isEmpty(note.reactions)) {
    // Remove existing reaction container if there are no reactions
    const existingContainer = document.querySelector(`svg > g[reaction-epubcfi="${note.cfiRange}"]`);
    if (existingContainer) {
      existingContainer.remove();
    }
    return;
  }

  const svg = document.querySelector('.epub-view > svg');
  if (!svg) {
    logger.warn('SVG element not found');
    return;
  }

  let reactionContainer = svg.querySelector(`g[reaction-epubcfi="${note.cfiRange}"]`);
  if (!reactionContainer) {
    reactionContainer = createSVG('g');
    reactionContainer.setAttribute('reaction-epubcfi', note.cfiRange);
    svg.appendChild(reactionContainer);
  } else {
    // Clear existing reactions
    reactionContainer.innerHTML = '';
  }

  const highlightRects = Array.from(
    svg.querySelectorAll(`g[data-epubcfi="${note.cfiRange}"] > rect`)
  ) as SVGRectElement[];
  if (highlightRects.length === 0) {
    logger.warn('No highlight rects found for note with id: ', note.id, 'cfiRange = ', note.cfiRange);
    return;
  }

  // Sort rectangles: bottommost (largest y + height) first, then rightmost (largest x + width)
  highlightRects.sort((a: SVGRectElement, b: SVGRectElement) => {
    const aBottom = parseFloat(a.getAttribute('y') || '0') + parseFloat(a.getAttribute('height') || '0');
    const bBottom = parseFloat(b.getAttribute('y') || '0') + parseFloat(b.getAttribute('height') || '0');
    if (aBottom !== bBottom) {
      return bBottom - aBottom; // Descending order for y + height
    }
    const aRight = parseFloat(a.getAttribute('x') || '0') + parseFloat(a.getAttribute('width') || '0');
    const bRight = parseFloat(b.getAttribute('x') || '0') + parseFloat(b.getAttribute('width') || '0');
    return bRight - aRight; // Descending order for x + width
  });

  const lastRect = highlightRects[0];
  const bottomRightX =
    parseFloat(lastRect.getAttribute('x') || '0') + parseFloat(lastRect.getAttribute('width') || '0');
  const bottomRightY =
    parseFloat(lastRect.getAttribute('y') || '0') + parseFloat(lastRect.getAttribute('height') || '0');

  reactionContainer.setAttribute('transform', `translate(${bottomRightX}, ${bottomRightY})`);

  let xOffset = 0;
  Object.entries(note.reactions).forEach(([user, emoji]) => {
    const reactionGroup = createSVG('g');
    reactionGroup.setAttribute('transform', `translate(${xOffset}, 0)`);

    // const reactionBg = createSVG('circle');
    // reactionBg.setAttribute('r', `${emojiSize / 1.5}px`);
    // reactionBg.setAttribute('fill', getTheme() === 'dark' ? '#333' : '#ccc');
    // reactionBg.setAttribute('fill-opacity', '1');
    // reactionBg.setAttribute('stroke', getTheme() === 'dark' ? '#888' : '#888');
    // reactionBg.setAttribute('stroke-width', '1');

    const reactionText = createSVG('text');
    reactionText.textContent = emoji;
    reactionText.setAttribute('font-size', `${emojiSize}px`);
    reactionText.setAttribute('text-anchor', 'middle');
    reactionText.setAttribute('dominant-baseline', 'central');

    // reactionGroup.appendChild(reactionBg);
    reactionGroup.appendChild(reactionText);

    // if (users.length > 1) {
    //   const countText = createSVG('text');
    //   countText.textContent = users.length.toString();
    //   countText.setAttribute('font-size', '8');
    //   countText.setAttribute('text-anchor', 'middle');
    //   countText.setAttribute('dominant-baseline', 'hanging');
    //   countText.setAttribute('y', '10');
    //   reactionGroup.appendChild(countText);
    // }

    reactionContainer.appendChild(reactionGroup);
    xOffset += 18; // Adjust spacing between reactions
  });
};

const shortDelay = 100;
const mediumDelay = 250;
const longDelay = 1000;

export const getUpdates = debounce(getUpdatesBase, mediumDelay);
export const reportOpen = debounce(reportOpenBase, mediumDelay);
export const getBookProgress = debounce(getBookProgressBase, mediumDelay);
export const getStreaks = debounce(getStreaksBase, mediumDelay);
export const fetchFollowing = debounce(fetchFollowingBase, mediumDelay);
