import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import type { BookMeta, BookSettings, Prefs, StatusBarBoxInfo } from '../types/book';
import { EpubView } from './EpubView';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { type NavItem } from 'epubjs';
import { checkIfFullscreen, getPrefs, getTheme } from '../utils/books';
import StatusBarBox from './StatusBarBox';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface TopStatusBarProps {
  onToggle: () => void;
  meta: BookMeta;
  precision?: number;
  getReader: () => EpubView | null;
  currentWPM?: number;
  overallWPM?: number;
  settings: BookSettings;
  chapterProgress?: number;
  prefs: Prefs;
  updatePrefs: (prefs: Prefs) => void;
}

const flattenTOC = (toc: NavItem[]) => {
  let flat: NavItem[] = [];
  toc.forEach((item: NavItem) => {
    flat.push({ ...item, href: item.href.split('#')[0] });
    if (item.subitems) {
      flat = flat.concat(flattenTOC(item.subitems));
    }
  });
  return flat;
};

let holdTimeout: NodeJS.Timeout | null = null;

// Define long and short entries
const longEntries = ['chapter_name', 'book_name', 'author_name', 'epubcfi'];
const shortEntries = [
  'wpm',
  'chapter_progress',
  'chapter_time',
  'book_progress',
  'book_location',
  'book_time',
  'book_elapsed',
  'none',
];

const TopStatusBar: React.FC<TopStatusBarProps> = ({
  onToggle,
  meta,
  precision,
  getReader,
  currentWPM,
  overallWPM,
  settings,
  chapterProgress,
  prefs,
  updatePrefs,
}) => {
  const reader = getReader();
  const loc = reader?.rendition?.location;
  const page = loc?.start.displayed.page;
  const total = loc?.start.displayed.total;
  const flatToc = flattenTOC(reader?.state.toc || []);
  const wordsRemaining =
    (chapterProgress !== undefined &&
      loc?.start.index &&
      meta.chapterWordCount &&
      meta.chapterWordCount.length > loc.start.index &&
      (1 - chapterProgress) * meta.chapterWordCount[loc.start.index]) ||
    undefined;
  const minutesRemaining =
    (chapterProgress !== undefined && wordsRemaining !== undefined && currentWPM && wordsRemaining / currentWPM) ||
    undefined;
  const wordsRemainingBook =
    (settings.progress !== undefined && meta.wordCount && (1 - settings.progress) * meta.wordCount) || undefined;
  const minutesRemainingBook =
    (settings.progress !== undefined &&
      wordsRemainingBook !== undefined &&
      overallWPM &&
      wordsRemainingBook / overallWPM) ||
    undefined;
  const minutesElapsedBook =
    (settings.progress !== undefined &&
      wordsRemainingBook !== undefined &&
      overallWPM &&
      meta.wordCount &&
      (meta.wordCount - wordsRemainingBook) / overallWPM) ||
    undefined;

  const nextStatusBarBox: { [key: string]: string } = {
    chapter_name: 'book_name',
    book_name: 'author_name',
    author_name: 'epubcfi',
    epubcfi: 'chapter_name',
    // left_none: "chapter_name",

    chapter_progress: 'chapter_time',
    chapter_time: 'wpm',
    wpm: 'chapter_none',
    chapter_none: 'chapter_progress',

    book_progress: 'book_location',
    book_location: 'book_time',
    book_time: 'book_elapsed',
    book_elapsed: 'book_none',
    book_none: 'book_progress',
  };

  const formatMinutes = (minutes: number, alt = false) => {
    if (alt) {
      return `${Math.floor(minutes / 60)}:${Math.floor(minutes % 60)
        .toString()
        .padStart(2, '0')}`;
    } else if (minutes >= 60) {
      return `${Math.floor(minutes / 60)}h ${Math.floor(minutes % 60)}m`;
    } else {
      return `${Math.round(minutes).toString()}m`;
    }
  };

  const handleStatusBarBoxClick = (event: Event, box: StatusBarBoxInfo) => {
    event.stopPropagation();
    updatePrefs({
      statusBarBoxes: [
        ...prefs.statusBarBoxes.filter((b) => b.info !== box.info || b.position !== box.position),
        {
          position: box.position,
          info: nextStatusBarBox[box.info] ?? box.info,
        },
      ],
    });
  };

  const getBoxInfo = (info: string): string => {
    if (info === 'wpm') {
      return (currentWPM && `${Math.round(currentWPM)}w`) || '(wpm)';
    } else if (info === 'chapter_progress') {
      return settings.flow === 'paginated'
        ? `${page}/${total}`
        : chapterProgress === undefined
        ? '--'
        : `${(chapterProgress * 100).toFixed(0)}%`;
    } else if (info === 'chapter_time') {
      return (minutesRemaining !== undefined && formatMinutes(minutesRemaining)) || '(ch. time left)';
    } else if (info === 'book_time') {
      return (minutesRemainingBook !== undefined && formatMinutes(minutesRemainingBook)) || '(time left)';
    } else if (info === 'book_elapsed') {
      return (minutesElapsedBook !== undefined && formatMinutes(minutesElapsedBook, true)) || '(time spent)';
    } else if (info === 'book_progress') {
      return `${((meta.settings.progress ?? 0) * 100.0).toFixed(
        precision !== undefined && precision > 0 ? Math.ceil(precision) : 0
      )}%`;
    } else if (info === 'book_name') {
      return meta.title || '(no title)';
    } else if (info === 'book_location') {
      return `${getReader()?.rendition?.location?.start.location}`;
    } else if (info === 'author_name') {
      return meta.author || '(no author)';
    } else if (info === 'epubcfi') {
      return meta.settings.location || '(cfi)';
    } else if (info === 'chapter_name') {
      return chapter || '⁘';
    } else if (info === 'chapter_none') {
      return '\u2234';
    } else if (info.includes('thus')) {
      return '\u2235';
    } else if (info.includes('diamond')) {
      return '⁘';
    } else {
      return '';
    }
  };

  const chapter =
    loc?.start.href &&
    flatToc.find((i) => loc.start.href.endsWith(i.href) || i.href.endsWith(loc.start.href))?.label.trim();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBox, setSelectedBox] = useState<StatusBarBoxInfo | null>(null);

  const handleStatusBarBoxHold = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
    box: StatusBarBoxInfo
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedBox(box);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBox(null);
  };

  const handleMenuItemClick = (newInfo: string) => {
    if (selectedBox) {
      const isCurrentlyLong = longEntries.includes(selectedBox.info);
      const isNewLong = longEntries.includes(newInfo);
      const isNewShort = shortEntries.includes(newInfo);

      let updatedBoxes = prefs.statusBarBoxes.filter((b) => b.position * selectedBox.position < 0);

      if (isNewLong) {
        // If changing to a long entry, remove any entries with position ±2
        // updatedBoxes = updatedBoxes.filter((b) => b.position !== (selectedBox.position < 0 ? -2 : 2));

        // Ensure the long entry takes position ±1
        const newPosition = selectedBox.position < 0 ? -1 : 1;
        updatedBoxes.push({
          position: newPosition,
          info: newInfo,
        });
      } else if (isNewShort) {
        // Add the selected new info
        updatedBoxes.push({
          position: selectedBox.position,
          info: newInfo,
        });

        // If changing from long to short, add a "none" entry if needed
        if (isCurrentlyLong) {
          const otherPosition = selectedBox.position < 0 ? selectedBox.position + 1 : selectedBox.position - 1;
          const existingEntry = updatedBoxes.find((b) => b.position === otherPosition);
          updatedBoxes.push({
            position: otherPosition,
            info: existingEntry ? existingEntry.info : 'none',
          });
        }
      }

      updatePrefs({
        statusBarBoxes: updatedBoxes.sort((a, b) => a.position - b.position),
      });
    }
    handleMenuClose();
  };

  const allBoxOptions = [
    'chapter_name',
    'book_name',
    'author_name',
    'epubcfi',
    'wpm',
    'chapter_progress',
    'chapter_time',
    'book_progress',
    'book_location',
    'book_time',
    'book_elapsed',
    'none',
  ];

  const renderStatusBoxes = (side: 'left' | 'right') => {
    const boxes = prefs.statusBarBoxes
      .filter((box) => (side === 'left' && box.position < 0) || (side === 'right' && box.position > 0))
      .sort((a, b) => (side === 'left' ? b.position - a.position : a.position - b.position));

    if (boxes.length === 0) return null;

    const isLongEntry = longEntries.includes(boxes[0].info);

    return (
      <Stack direction='row' spacing={1} fontSize={{ xs: 'small', sm: 'medium', md: 'large' }} sx={{ flex: 1 }}>
        <StatusBarBox
          key={boxes[0].position}
          content={getBoxInfo(boxes[0].info)}
          onHold={onToggle}
          onClick={(e) => handleStatusBarBoxHold(e, boxes[0])}
          boxInfo={boxes[0]}
          sx={{
            pr: side === 'right' ? 1.5 : 0,
            flex: isLongEntry ? 1 : 0.5,
            textAlign: side === 'left' ? 'left' : 'right',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
          }}
        />
        {!isLongEntry && boxes[1] && (
          <StatusBarBox
            key={boxes[1].position}
            content={getBoxInfo(boxes[1].info)}
            onHold={onToggle}
            onClick={(e) => handleStatusBarBoxHold(e, boxes[1])}
            boxInfo={boxes[1]}
            sx={{
              pr: side === 'right' ? 1.5 : 0,
              flex: 0.5,
              textAlign: side === 'left' ? 'left' : 'right',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          />
        )}
      </Stack>
    );
  };

  return (
    <AppBar
      position='fixed'
      elevation={checkIfFullscreen() ? 0 : 4}
      sx={{
        color: '#888',
        backgroundColor: Boolean(document.fullscreenElement)
          ? 'transparent'
          : getTheme(prefs.theme) === 'dark'
          ? '#111'
          : '#eee',
        backgroundImage: 'none',
      }}
    >
      <Toolbar disableGutters variant='dense' sx={{ alignItems: 'stretch' }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              flex: 5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MenuIcon sx={{ mx: 1.5 }} onClick={onToggle} />
            {renderStatusBoxes('left')}
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box
            sx={{
              flex: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStatusBoxes('right')}
          </Box>
        </Box>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          dense: true,
        }}
      >
        {allBoxOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            sx={{
              py: 1,
            }}
          >
            {option.replace('_', ' ')}
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
};

export default TopStatusBar;
