import { useState } from 'react';
import { Link, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Markdown from 'react-markdown';
import { getTheme } from '../utils/books';
import CloseIcon from '@mui/icons-material/Close';
import changelog from '../../CHANGELOG.md?raw';
import autochangelog from '../../AUTO_CHANGELOG.md?raw';

interface ChangelogProps {
  onClose: () => void;
}

export function Changelog({ onClose }: ChangelogProps) {
  const [isAutoChangelog, setIsAutoChangelog] = useState(false);

  return (
    <Dialog open={true} onClose={onClose} sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {isAutoChangelog ? 'Version history' : 'Release notes'}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: getTheme() === 'dark' ? 'white' : 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: '0.9em',
          '& a': {
            color: getTheme() === 'dark' ? '#6495ED' : '#4169E1',
            textDecoration: 'none',
          },
        }}
      >
        <Link component='button' onClick={() => setIsAutoChangelog(!isAutoChangelog)}>
          {isAutoChangelog ? 'Show release notes' : 'Show version history'}
        </Link>
        <Markdown>{isAutoChangelog ? autochangelog : changelog}</Markdown>
      </DialogContent>
    </Dialog>
  );
}
