import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getBooks,
  archiveBook,
  getCurrentUser,
  logoutUser,
  fetchUser,
  getPrefs,
  fetchFollowing,
  getBookProgress,
  getStreaks,
  aggregateWeeklyTime,
  addBookFromFile,
  replaceBookFile,
  deleteBook,
  getVersion,
  upgrade,
  getTheme,
} from '../utils/books';
import BookCover from './BookCover';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import type { BookMeta, Prefs, UserInfo } from '../types/book';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { saveFile, getDB, saveObject } from '../utils/indexedDB';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReadingChart from './ReadingChart';
import dayjs from 'dayjs';
import { currentStoreVersion } from '../config';
import { debounce } from '../utils/core';
import { version } from '../../package.json';
import changelog from '../../CHANGELOG.md?raw';
import Markdown from 'react-markdown';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { checkVersion } from '../utils/api';
import { AppContext } from '../context/AppContext';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import { Changelog } from './Changelog';

const clientId = '23573608440-367kogg7qm9p84ai8g82miac8pshhkse.apps.googleusercontent.com';

const BookList: React.FC = () => {
  const { setSnackbarMessage, checkAppVersion, isOnline } = useContext(AppContext);
  const navigate = useNavigate();
  const [replaceFileId, setReplaceFileId] = useState<string | null>(null);
  const [user, setUser] = useState<UserInfo | null>(getCurrentUser());
  const [prefs, setPrefs] = useState<Prefs>(getPrefs());
  const [progressInfo, setProgressInfo] = useState<{ [hash: string]: any }>({});
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [bookFilter, setBookFilter] = useState<string>('active');
  const [books, setBooks] = useState<{ [id: string]: BookMeta }>(getBooks());

  const [streaks, setStreaks] = useState<{
    [id: string]: { days: number; today: boolean };
  }>({});
  const [weeklyTime, setWeeklyTime] = useState<{ label: string; value: number }[]>([]);
  const [streakTime, setStreakTime] = useState<{ label: string; value: number }[]>([]);

  const { cmd } = useParams<{ cmd: string }>(); // Assuming dynamic routing based on book ID

  const filteredBooks = useMemo(() => {
    return Object.entries(books)
      .filter(([id, book]) => {
        if (bookFilter.toLowerCase() === 'active') return book.location === 'active';
        if (bookFilter.toLowerCase() === 'archived') return book.location === 'archived';
        if (bookFilter.toLowerCase() === 'all') return book.location !== 'trash';
        return false;
      })
      .sort((a, b) => (b[1].timestamp || 0) - (a[1].timestamp || 0));
  }, [books, bookFilter]);

  const sortBooks = (books: BookMeta[]): BookMeta[] => {
    return books.sort((a, b) => {
      const timeDiff = (b.timestamp || 0) - (a.timestamp || 0);
      if (timeDiff !== 0) return timeDiff;
      return (a.title || '').localeCompare(b.title || '');
    });
  };

  useEffect(() => {
    const oldStoreVersion = getVersion();
    const debouncedUpgrade = debounce(upgrade, 1000);
    if (oldStoreVersion < currentStoreVersion) {
      console.debug('oldVersion', oldStoreVersion, 'current version', currentStoreVersion);
      setSnackbarMessage({ text: 'Upgrading internals... please wait...', duration: null });

      debouncedUpgrade().then(() => {
        const storeVersion = getVersion();
        console.log('version and old', storeVersion, oldStoreVersion);
        if (storeVersion !== oldStoreVersion) {
          console.log('upgraded to version', storeVersion);
          setSnackbarMessage({ text: `Storage upgraded to version ${storeVersion}. Reloading...`, duration: 3000 });
          window.location.reload();
        }
      });
    }
    return () => {
      debouncedUpgrade.cancel();
    };
  }, []);

  useEffect(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, []);

  //   useEffect(() => {
  //     if (cmd !== 'no-upgrade') {
  //       checkAppVersion();
  //     } else {
  //       console.log('not checking version');
  //       // change the apparent URL back to default
  //       const url = new URL(window.location.href);
  //       url.pathname = import.meta.env.BASE_URL;
  //       window.history.pushState({ path: url.href }, '', url.href);
  //     }
  //   }, [checkAppVersion, cmd]);

  const getStreakEmoji = (today: boolean): string => {
    if (today) return '🔥';

    const now = dayjs();
    const midnight = now.endOf('day');
    const hoursUntilMidnight = midnight.diff(now, 'hour');

    // Get clock emoji based on current hour
    const hour = now.hour();
    const clockEmoji = ['🕛', '🕐', '🕑', '🕒', '🕓', '🕔', '🕕', '🕖', '🕗', '🕘', '🕙', '🕚'][hour % 12];

    if (hoursUntilMidnight > 18) return `😊 ${clockEmoji}`;
    if (hoursUntilMidnight > 15) return `🙂 ${clockEmoji}`;
    if (hoursUntilMidnight > 12) return `😐 ${clockEmoji}`;
    if (hoursUntilMidnight > 10) return `😕 ${clockEmoji}`;
    if (hoursUntilMidnight > 8) return `😟 ${clockEmoji}`;
    if (hoursUntilMidnight > 6) return `😔 ${clockEmoji}`;
    if (hoursUntilMidnight > 5) return `😞 ${clockEmoji}`;
    if (hoursUntilMidnight > 4) return `😢 ${clockEmoji}`;
    if (hoursUntilMidnight > 3) return `😰 ${clockEmoji}`;
    if (hoursUntilMidnight > 2) return `😭 ${clockEmoji}`;
    if (hoursUntilMidnight > 1) return `😨 ${clockEmoji}`;
    return `😱😱😱 ${clockEmoji}`;
  };

  const sortBookObject = (bookObject: { [id: string]: BookMeta }): { [id: string]: BookMeta } => {
    const bookArray = Object.values(bookObject);
    const sortedBooks = sortBooks(bookArray);
    return sortedBooks.reduce((obj, book) => {
      obj[book.id] = book;
      return obj;
    }, {} as { [id: string]: BookMeta });
  };

  // Load progress info when books change
  useEffect(() => {
    const loadProgress = async () => {
      const hashes = Object.values(books)
        .map((book) => book.hash)
        .filter((hash): hash is string => hash !== undefined);

      const progress = await getBookProgress(hashes);
      setProgressInfo(progress);
    };

    if (books && Object.keys(books).length > 0) {
      loadProgress();
    }
  }, [books]);

  useEffect(() => {
    const apply = async () => {
      setPrefs(getPrefs());
      await fetchFollowing();
    };
    apply();
  }, []);

  const refreshBookList = () => {
    console.info('refreshing book list');
    const booksObject = getBooks();
    const sortedBooks = sortBookObject(booksObject);

    setBooks(sortedBooks);

    const updateWeeklyTime = async () => {
      const time = await aggregateWeeklyTime();
      setWeeklyTime(time);
    };
    updateWeeklyTime();
  };

  useEffect(() => {
    if (cmd === 'authed') {
      console.log('authed, cmd=', cmd, ' user=', user);
      setUser(fetchUser() || null);
      window.location.href = import.meta.env.BASE_URL;
    } else if (cmd === 'badurl') {
      // TODO: implement
    } else if (cmd) {
      console.log('cmd = ', cmd);
    }
  }, [cmd]);

  useEffect(() => {
    const loadStreaks = async () => {
      if (user?.email) {
        const streaks = await getStreaks([user.email]);
        if (streaks) {
          setStreaks(streaks);

          const streakTime = await aggregateWeeklyTime(streaks[user.email].days, 'dayOfMonth');
          setStreakTime(streakTime);
        }
      }
    };
    console.log('loading streaks from BookList for ', user?.email);
    if (user?.email) {
      loadStreaks();
    }
  }, [user?.email]);

  const createBackupZip = async () => {
    const zip = new JSZip();

    // Add localStorage data to the zip file
    const localStorageData = { ...localStorage };
    const localStorageBlob = new Blob([JSON.stringify(localStorageData)], {
      type: 'application/json',
    });
    zip.file('localStorageBackup.json', localStorageBlob);

    // Add IndexedDB files to the zip file
    const db = await getDB();
    const transaction = db.transaction(['ePubFiles'], 'readonly');
    const ePubFilesStore = transaction.objectStore('ePubFiles');
    // const ePubObjectsStore = transaction.objectStore('ePubObjects');

    const ePubFilesKeys = await ePubFilesStore.getAllKeys();
    // const ePubObjectsKeys = await ePubObjectsStore.getAllKeys();

    const metadataStore: { [key: string]: any } = {};

    // Helper function to get all files and their metadata
    const getAllFiles = async (store: any, keys: any, folderName: any) => {
      for (const key of keys) {
        const file = await store.get(key);
        if (file) {
          zip.file(`${folderName}/${key}`, file);

          // If the file is not a cover, save its metadata
          if (!key.startsWith('cover-')) {
            metadataStore[key] = {
              name: file.name,
              type: file.type,
              lastModified: file.lastModified,
            };
          }
        }
      }
    };

    // Wait for all files to be added to the zip
    await Promise.all([
      getAllFiles(ePubFilesStore, ePubFilesKeys, 'ePubFiles'),
      //   getAllFiles(ePubObjectsStore, ePubObjectsKeys, 'ePubObjects'),
    ]);

    // Add metadata to the zip file
    const metadataBlob = new Blob([JSON.stringify(metadataStore)], {
      type: 'application/json',
    });
    zip.file('metadataBackup.json', metadataBlob);

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Save or share the zip file
    const saveOrShareZip = async (blob: Blob) => {
      if (navigator.share) {
        const file = new File([blob], 'backup.zip', {
          type: 'application/zip',
        });
        try {
          await navigator.share({
            files: [file],
            title: 'Backup Data',
            text: 'Here is the backup of your eReader data.',
          });
          console.log('Shared successfully');
        } catch (error) {
          console.error('Error sharing', error);
          saveAs(blob, 'backup.zip');
        }
      } else {
        saveAs(blob, 'backup.zip');
      }
    };

    saveOrShareZip(zipBlob);
  };

  const loadFromBackup = async (file: File) => {
    try {
      const zip = await JSZip.loadAsync(file);

      // Load localStorage data from the zip file
      const localStorageFile = zip.file('localStorageBackup.json');
      if (localStorageFile) {
        const localStorageBackup = await localStorageFile.async('text');
        const localStorageData = JSON.parse(localStorageBackup);
        for (const key in localStorageData) {
          localStorage.setItem(key, localStorageData[key]);
        }
        console.log('localStorage reloaded successfully');
      } else {
        console.warn('localStorageBackup.json not found in the zip');
      }

      // Load metadata from the zip file
      const metadataFile = zip.file('metadataBackup.json');
      let metadataStore: { [key: string]: any } = {};
      if (metadataFile) {
        const metadataBackup = await metadataFile.async('text');
        metadataStore = JSON.parse(metadataBackup);
      } else {
        console.warn('metadataBackup.json not found in the zip');
      }

      // Load IndexedDB data from the zip file
      const ePubFilesFolder = zip.folder('ePubFiles');
      const ePubObjectsFolder = zip.folder('ePubObjects');

      if (ePubFilesFolder) {
        console.log('Processing ePubFiles...');
        ePubFilesFolder.forEach(async (relativePath, file) => {
          console.log(`Reading file: ${relativePath}`);
          const fileContent = await file.async('blob');
          if (fileContent) {
            if (relativePath.startsWith('cover-')) {
              // Save as Blob for covers
              console.log(`Saving cover blob ${relativePath} to IndexedDB`);
              await saveFile(
                relativePath,
                fileContent.type ? fileContent : new Blob([fileContent], { type: 'image/jpeg' })
              );
            } else {
              // Save as File for EPUB files
              const metadata = metadataStore[relativePath];
              let fileObject;
              if (metadata) {
                fileObject = new File([fileContent], metadata.name, {
                  type: metadata.type,
                  lastModified: metadata.lastModified,
                });
              } else {
                fileObject = new File([fileContent], file.name, {
                  type: fileContent.type || 'application/epub+zip',
                  lastModified: new Date().getTime(),
                });
              }
              console.log(`Saving file ${relativePath} to IndexedDB`);
              await saveFile(relativePath, fileObject);
            }
          } else {
            console.warn(`Failed to read file content for ${relativePath}`);
          }
        });
        console.log('ePubFiles reloaded successfully');
      } else {
        console.warn('No ePubFiles folder found in the zip');
      }

      if (ePubObjectsFolder) {
        console.log('Processing ePubObjects...');
        ePubObjectsFolder.forEach(async (relativePath, file) => {
          console.log(`Reading file: ${relativePath}`);
          const fileContent = await file.async('text');
          if (fileContent) {
            const parsedObject = JSON.parse(fileContent);
            console.log(`Saving object ${relativePath} to IndexedDB`);
            await saveObject(relativePath, parsedObject);
          } else {
            console.warn(`Failed to read object content for ${relativePath}`);
          }
        });
        console.log('ePubObjects reloaded successfully');
      } else {
        console.warn('No ePubObjects folder found in the zip');
      }

      console.log('Backup loaded successfully.');
    } catch (error) {
      console.error('Error loading backup:', error);
    }
  };

  // TODO: refreshbooklist after adding books
  // TODO: snackbar on error adding books

  const handleLoadBackup = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSnackbarMessage({ text: 'No files selected. You do you.', duration: 3000 });
      return;
    }
    const file = e.target.files[0];
    setSnackbarMessage({ text: 'Loading backup... just a sec...', duration: null });
    await loadFromBackup(file);
    setSnackbarMessage({ text: 'Backup loaded successfully!', duration: 3000 });
    refreshBookList();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSnackbarMessage({ text: 'No files selected, but you do you.', duration: 3000 });
      return;
    }

    const handleMultipleFileUpload = async () => {
      if (!files) {
        return;
      }

      const uploadPromises = Array.from(files).map((file) => addBookFromFile(file));
      try {
        setSnackbarMessage({ text: `Adding book${files.length > 1 ? 's' : ''}...`, duration: null });
        const addedBooks = await Promise.all(uploadPromises);
        const validBooks = addedBooks.filter(Boolean);

        if (validBooks.length === 0) {
          setSnackbarMessage({
            text: 'Every book failed to be added. Not ideal. Try again, and if it keeps failing, ask Trevor.',
            duration: 3000,
          });
        } else if (validBooks.length === 1) {
          setSnackbarMessage({ text: `${validBooks[0]} added.`, duration: 3000 });
        } else {
          const firstBook = validBooks[0];
          const otherBooksCount = validBooks.length - 1;
          setSnackbarMessage({
            text: `${firstBook} (and ${otherBooksCount} more) added successfully!`,
            duration: 3000,
          });
        }

        refreshBookList();
      } catch (error) {
        console.error('Error adding books:', error);
        setSnackbarMessage({
          text: "Failed to add one or more books. Congrats, you're the first one to find this particular bug! Tell Trevor.",
          duration: null,
        });
      }
    };

    handleMultipleFileUpload();
  };

  const handleToggleArchive = async (id: string) => {
    archiveBook(id);
    // setBooks(getBooks());
    refreshBookList();

    console.log('toggled archive on book with id: ', id, books);
  };

  const handleDeleteBook = async (id: string) => {
    console.log('handle delete book');
    const books = await deleteBook(id);
    setBooks(books);
  };

  const handleReplaceFileClick = (id: string) => {
    // Assuming replaceBookFile is an API to replace the book file
    console.debug('handleReplaceFileClick', id);
    setReplaceFileId(id);
    fileInputRef.current?.click();
  };

  const handleReplaceFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.debug('handleReplaceFileChange', event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files?.[0];
      if (replaceFileId && file) {
        console.log('File selected, id:', file.name, replaceFileId);
        await replaceBookFile(replaceFileId, file);
        setReplaceFileId(null);
        setSnackbarMessage({ text: '✅📚 Book file replaced successfully!', duration: 5000 });
      }
    }
  };

  const openBook = (id: string) => {
    navigate(`/read/${id}`);
  };

  const handleCustomLogout = () => {
    setUser(null);
    logoutUser();
  };

  const redirectUri = 'https://read.by.tc/auth/callback';
  const scope = 'email profile openid';
  const responseType = 'code';

  useEffect(() => {
    setUser(fetchUser() || null);
  }, []);

  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&scope=${encodeURIComponent(scope)}&response_type=${responseType}&access_type=offline&prompt=consent`;

  const shelfDropdown = () => {
    return (
      <FormControl sx={{ minWidth: 140 }}>
        <InputLabel
          id='book-filter-label'
          sx={{
            fontSize: '1em',
            color: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Shelf
        </InputLabel>
        <Select
          labelId='book-filter-label'
          value={bookFilter}
          label='Show'
          onChange={(e) => setBookFilter(e.target.value as string)}
          sx={{
            color: getTheme() === 'dark' ? '#fff' : '#000',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: getTheme() === 'dark' ? '#90caf9' : '#1976d2',
            },
            '& .MuiSvgIcon-root': {
              color: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
            },
            '& .MuiInputBase-input': {
              padding: '5px 1em',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: getTheme() === 'dark' ? '#424242' : '#fff',
                '& .MuiMenuItem-root': {
                  color: getTheme() === 'dark' ? '#fff' : '#000',
                },
              },
            },
          }}
        >
          <MenuItem value='active'>Active</MenuItem>
          <MenuItem value='archived'>Archived</MenuItem>
          <MenuItem value='all'>All</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <Container
      sx={{
        backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
        paddingTop: '8px',
      }}
    >
      {import.meta.env.DEV && (
        <Typography
          variant='caption'
          color='#888'
          sx={{
            fontSize: '0.5rem',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          (dev mode)
        </Typography>
      )}
      <input
        ref={fileInputRef}
        type='file'
        hidden
        onChange={(e) => handleReplaceFileChange(e)} // Ensure you have a handler for file changes
      />
      <Stack
        direction='row'
        spacing={1.5}
        margin='16px 0px'
        alignItems='center'
        sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}
      >
        <Button
          variant='contained'
          component='label'
          sx={{
            margin: '16px',
            width: 'auto',
            padding: '4px 8px',
            textTransform: 'none',
            backgroundColor: '#5c6bc0',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#3949ab',
            },
          }}
        >
          Add
          <input
            type='file'
            hidden
            multiple
            // name="*.epub"
            accept='.epub,application/epub+zip'
            onChange={handleFileChange}
          />
        </Button>
        {user ? (
          <Button
            onClick={handleCustomLogout}
            variant='outlined'
            component='label'
            sx={{
              margin: '16px 8px 16px 0px',
              width: 'auto',
              padding: '4px 8px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            Logout
          </Button>
        ) : (
          <Button
            onClick={() => (window.location.href = authUrl)}
            variant='contained'
            component='label'
            sx={{
              //   margin: "16px 8px 16px 0px",
              //   width: "auto",
              padding: '4px 8px',
              textTransform: 'none',
              backgroundColor: '#5c6bc0',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            Login
          </Button>
        )}
        <Box flexGrow={1} />
        <IconButton
          onClick={() => window.location.reload()}
          sx={{
            // margin: "8px",
            padding: '0px 0',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#3949ab',
            },
          }}
        >
          {isOnline ? <RefreshIcon color='primary' /> : <OfflineBoltIcon style={{ color: '#cc0' }} />}
        </IconButton>
        <Box flexGrow={1} />
        <Button
          variant='outlined'
          component='label'
          onClick={() => {
            console.log('backing the f up!');
            setSnackbarMessage({
              text: 'Backing up, one moment. You should get a zip file momentarily with all of your saved books and highlights.',
              duration: null,
            });
            return createBackupZip();
          }}
          sx={{
            // margin: "16px",
            width: 'auto',
            padding: '4px 8px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#3949ab',
            },
          }}
        >
          Backup
        </Button>
        <Button
          variant='outlined'
          component='label'
          sx={{
            textTransform: 'none',
            padding: '4px 8px',

            '&:hover': {
              backgroundColor: '#3949ab',
            },
          }}
        >
          Restore
          <input id='backupInput' type='file' hidden accept='application/zip' onChange={handleLoadBackup} />
        </Button>
      </Stack>
      <Stack
        direction='row'
        sx={{
          m: '0 0px 32px 0',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        spacing={1}
      >
        {shelfDropdown()}
        <Box flexGrow={1} />
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => setChangelogOpen(true)}
          sx={{ textTransform: 'none', p: 0.5 }}
        >
          v{version}
        </Button>
      </Stack>
      <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
        {user && (
          <>
            <Avatar
              //   alt={user.given_name}
              src={user.picture}
            />
            <Typography
              sx={{
                color: getTheme() === 'dark' ? 'white' : 'black',
                fontWeight: 'bold',
                fontSize: '150%',
              }}
            >
              {/* <span style={{ color: "white", fontWeight: "bold" }}> */}
              {user.email && streaks?.[user.email]?.days
                ? `${streaks[user.email].days} day streak${streaks[user.email].today ? '!' : ''} ${getStreakEmoji(
                    streaks[user.email].today
                  )}`
                : `Hi, ${user.given_name ?? 'you'}!`}
            </Typography>
          </>
        )}
      </Stack>
      {isOnline && (
        <Container
          sx={{
            backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
            paddingTop: '8px',
          }}
        >
          {streakTime.length > 0 ? (
            <ReadingChart
              data={streakTime}
              dot={false}
              // isAnimationActive={false}
              isAnimationActive={true}
              animationDuration={4000}
              movingAverageWindow={
                streakTime.length >= 60 ? 14 : streakTime.length >= 30 ? 7 : streakTime.length >= 7 ? 3 : undefined
              }
            />
          ) : (
            // empty space while waiting for data
            <Box sx={{ height: '160px', width: '100%' }} />
          )}
          {/* {weeklyTime.reduce((a, b) => a + b.value, 0) > 0 && (
          <ReadingChart
            data={weeklyTime}
            isAnimationActive={true}
            animationDuration={4000}
          />
        )} */}
        </Container>
      )}

      <Stack
        justifyContent='center'
        spacing={1}
        paddingBottom={2}
        paddingTop={2}
        flexWrap='wrap'
        useFlexGap
        direction='row'
        overflow='auto'
        sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}
      >
        {filteredBooks.map(([id, book]) => (
          <Box
            key={book.id}
            onClick={() => openBook(book.id)}
            sx={{
              display: 'flex',
              backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
            }}
          >
            <BookCover
              book={book}
              onToggleArchive={() => handleToggleArchive(book.id)}
              onDeleteBook={() => handleDeleteBook(book.id)}
              isArchived={book.location === 'archived'}
              userProgress={progressInfo && book.hash ? progressInfo[book.hash] : {}}
              fileInputRef={fileInputRef}
              onReplaceFile={() => handleReplaceFileClick(book.id)}
            />
          </Box>
        ))}
      </Stack>

      {changelogOpen && <Changelog onClose={() => setChangelogOpen(false)} />}
    </Container>
  );
};

export default BookList;
