import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Rendition, type NavItem } from "epubjs";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import type Section from "epubjs/types/section";
import { getBack, guessCurrent } from "../utils/books";
import type { BookMeta } from "../types/book";
import Button from "@mui/material/Button";

interface TocComponentProps {
  toc: NavItem[];
  setLocation: (location: string) => void;
  rendition?: Rendition | undefined;
  closeDrawer: () => void;
  book: BookMeta;
  goToPercentage: (percentage: number) => void;
}

const TocComponent: React.FC<TocComponentProps> = ({
  toc,
  setLocation,
  rendition,
  closeDrawer,
  book,
  goToPercentage,
}) => {
  const theme = useTheme(); // Use the theme hook to access the current theme
  const [open, setOpen] = useState<string | null>(null);

  const findMatchingSection = (href: string) => {
    const section = (rendition?.book.spine as any).items.find((s: Section) =>
      s.href.endsWith(href.split("/").slice(-1)[0].split("#")[0])
    );
    return section;
  };

  const gotoHref = (href: string) => {
    const section = findMatchingSection(href);
    if (!section) {
      console.warn("Section not found:", href, " book.spine=", rendition?.book.spine);
      return;
    }
    console.log("gotoHref", section.href);
    setLocation(section.href);
    closeDrawer();
  };

  const handleClick = (id: string) => {
    setOpen(open === id ? null : id); // Toggle
  };

  const handleItemClick = (item: NavItem, id: string) => {
    if (item.subitems && item.subitems.length > 0) {
      handleClick(id);
    } else {
      gotoHref(item.href);
    }
  };

  const renderSubItems = (subitems: NavItem[], parentId: string) => {
    return subitems.map((subitem, subIndex) => {
      const subItemId = `${parentId}-${subIndex}`; // Create a unique ID for subitems
      return (
        <ListItemButton
          disableGutters
          key={subItemId} // Assign a unique key to each subitem
          sx={{ p: 0, pl: 4, bgcolor: theme.palette.background.paper }}
          onClick={() => gotoHref(subitem.href)}
        >
          <ListItemText primary={subitem.label} />
        </ListItemButton>
      );
    });
  };

  const renderTocItem = (item: NavItem, index: number) => {
    const id = `toc-item-${index}`;
    return (
      <React.Fragment key={id}>
        <ListItemButton onClick={() => handleItemClick(item, id)} disableGutters sx={{ p: 0 }}>
          <ListItemText primary={item.label} />
          {item.subitems && item.subitems.length > 0 && (open === id ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </ListItemButton>
        {item.subitems && (
          <Collapse in={open === id} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderSubItems(item.subitems, id)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          goToPercentage(guessCurrent(book.settings.updates, book.settings.progress));
          closeDrawer();
        }}
        sx={{ margin: "16px 0" }}
      >
        Find my spot
      </Button>
      <List
        sx={{
          width: "300px",
          maxWidth: "300px",
        }}
        component="nav"
      >
        {toc.map(renderTocItem)}
      </List>
    </>
  );
};

export default TocComponent;
