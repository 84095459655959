import { useState, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import SearchIcon from "@mui/icons-material/Search";
import sorries from "../data/sorries.json";

interface Result {
  synonyms?: string[];
  partOfSpeech: string;
  definitions: {
    definition: string;
    examples?: { example: string; translation?: string }[];
  }[];
}

interface Syllable {
  count: number;
  list: string[];
}

interface Word {
  word: string;
  pronunciation?: { all: string };
  syllables?: Syllable;
  results: Result[];
  frequency?: number;
  pron?: string;
  fpm?: string;
  success?: boolean;
  sorry?: string;
}

const partOfSpeechAbbreviations: { [key: string]: string } = {
  noun: "[n.]",
  verb: "[v.]",
  adjective: "[adj.]",
  adverb: "[adv.]",
  conjunction: "[conj.]",
  preposition: "[prep.]",
  interjection: "[interj.]",
  pronoun: "[pron.]",
  numeral: "[num.]",
  article: "[art.]",
};

interface WiktionaryViewProps {
  open: boolean;
  onClose: () => void;
  word: string;
  languageCode: string;
}

export const WiktionaryView = ({ open, onClose, word, languageCode }: WiktionaryViewProps) => {
  const [wiktionaryInfo, setWiktionaryInfo] = useState({ word: "" } as Word);
  const dialogContentRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(word.toLowerCase());

  const handleClose = () => {
    setLoading(false);
    setInputValue("");
    setWiktionaryInfo({ word: "" } as Word);
    onClose();
  };

  const Danger = ({ html }: { html: string | undefined }) => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: stripTagsAndWrapWords(html || "") || "",
        }}
      />
    );
  };

  const stripTagsAndWrapWords = (html: string) => {
    const allowedTags = ["b", "i", "em", "strong", "u", "ol", "ul", "li"];
    const doc = new DOMParser().parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((element) => {
      if (!allowedTags.includes(element.tagName.toLowerCase())) {
        const parent = element.parentNode;
        if (parent) {
          while (element.firstChild) {
            parent.insertBefore(element.firstChild, element);
          }
          parent.removeChild(element);
        }
      }
    });

    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null);

    const nodesToReplace: Node[] = [];
    while (walker.nextNode()) {
      nodesToReplace.push(walker.currentNode);
    }

    nodesToReplace.forEach((node) => {
      const words = node.textContent?.split(/\s+/) || [];
      const spanWrappedWords = words.map((word) => `<span class="clickable-word">${word}</span>`).join(" ");
      const spanWrappedFragment = document.createRange().createContextualFragment(spanWrappedWords);

      node.parentNode?.replaceChild(spanWrappedFragment, node);
    });

    return doc.body.innerHTML;
  };

  const handleWiktionaryLookup = async (word: string) => {
    const url = `https://en.wiktionary.org/api/rest_v1/page/definition/${encodeURIComponent(word)}?redirect=false`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Api-User-Agent": "tc@by.tc",
      },
    };

    setLoading(true);

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.debug("wiktionary result:", result);

      const stripTags = (html: string) => {
        const allowedTags = ["b", "i", "em", "strong", "u", "ol", "ul", "li"];
        const doc = new DOMParser().parseFromString(html, "text/html");
        const elements = doc.body.querySelectorAll("*");

        elements.forEach((element) => {
          if (!allowedTags.includes(element.tagName.toLowerCase())) {
            const parent = element.parentNode;
            if (parent) {
              while (element.firstChild) {
                parent.insertBefore(element.firstChild, element);
              }
              parent.removeChild(element);
            }
          }
        });

        return doc.body.innerHTML;
      };

      const filteredResults =
        result[languageCode]?.map((entry: any) => ({
          partOfSpeech: entry.partOfSpeech?.toLowerCase(),
          definitions: entry.definitions.map((def: any) => ({
            definition: stripTags(def.definition),
            examples: def.parsedExamples
              ? def.parsedExamples.map((ex: any) => ({
                  example: stripTags(ex.example),
                  translation: stripTags(ex.translation),
                }))
              : [],
          })),
        })) || [];

      console.debug("filteredResults", filteredResults);

      const wordData: Word = {
        word: word,
        results: filteredResults,
        success: filteredResults.length > 0,
        sorry: filteredResults.length === 0 ? sorries[Math.floor(Math.random() * sorries.length)] : undefined,
      };

      setWiktionaryInfo(wordData);
    } catch (error) {
      console.log(error);
      setWiktionaryInfo({} as Word);
    } finally {
      setLoading(false);
    }
  };

  const handleShareToAnki = () => {
    const share = async () => {
      if (dialogContentRef.current) {
        const innerText = dialogContentRef.current.innerText || "";
        let innerHTML = dialogContentRef.current.innerHTML || "";
        if (innerHTML) {
          innerHTML = innerHTML.replace(/ class="(.*?)"/g, "").replace(/ style="(.*?)"/g, "");
        }
        await navigator.share({
          text: innerHTML ? innerHTML : wiktionaryInfo.word,
          title: wiktionaryInfo.word,
        });
      }
    };
    share();
  };

  useEffect(() => {
    setInputValue(word.toLowerCase());
    if (open && word) {
      handleWiktionaryLookup(word.toLowerCase());
    }
  }, [open, word]);

  const handleWordClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const text = target.innerText;

    if (text) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(target);
      selection?.removeAllRanges();
      selection?.addRange(range);

      const selectedText = selection?.toString() || "";
      const clickedWord = selectedText
        .split(/[^\a-zA-ZĉĈĥĤĵĴŝŜŭŬĝĜàâäæçéèêëîïôœùûüÿÀÂÄÆÇÉÈÊËÎÏÔŒÙÛÜŸ\-\'\’]+/)
        .find((word) => {
          const rect = range.getBoundingClientRect();
          return (
            event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= rect.top &&
            event.clientY <= rect.bottom
          );
        });

      if (clickedWord) {
        console.log("clickedWord", clickedWord);
        const cleanWord = clickedWord.replace(/\u2019/g, "'").toLowerCase();
        setInputValue(cleanWord);
        handleWiktionaryLookup(cleanWord);
      }
    }
  };

  useEffect(() => {
    if (dialogContentRef.current) {
      const wordElements = dialogContentRef.current.querySelectorAll(".clickable-word");
      wordElements.forEach((element) => {
        const htmlElement = element as HTMLElement;
        htmlElement.style.cursor = "pointer";
        htmlElement.onclick = (event) => handleWordClick(event as MouseEvent);
      });
    }
  }, [wiktionaryInfo]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" gap={1}>
          <TextField
            fullWidth
            variant="outlined"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                handleWiktionaryLookup(inputValue);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Press Enter to lookup word">
                    <IconButton onClick={() => handleWiktionaryLookup(inputValue)} aria-label="lookup">
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {wiktionaryInfo.success && (
            <IconButton onClick={handleShareToAnki} aria-label="share" edge="end">
              <ShareIcon />
            </IconButton>
          )}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="caption" marginLeft="1.5em">
          {!wiktionaryInfo.success && wiktionaryInfo.sorry}
        </Typography>
      </DialogTitle>
      <DialogContent ref={dialogContentRef}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <CircularProgress />
          </Stack>
        ) : (
          wiktionaryInfo.results &&
          wiktionaryInfo.results.length > 0 && (
            <>
              {wiktionaryInfo.results.map((result, index) => (
                <div key={index}>
                  <Typography variant="h6" component="div" style={{ fontSize: "100%" }}>
                    {result.partOfSpeech}
                  </Typography>
                  <ol
                    style={{
                      listStyleType: "decimal",
                      fontSize: "100%",
                      paddingLeft: "25px",
                    }}
                  >
                    {result.definitions.map((definition, defIndex) => (
                      <li key={defIndex}>
                        <Danger html={definition.definition} />

                        {definition.examples && (
                          <ul
                            style={{
                              fontSize: "0.8em",
                              listStyleType: "circle",
                              fontStyle: "normal",
                              marginLeft: "0em",
                              marginTop: "0.25em",
                              marginBottom: "0.25em",
                              paddingLeft: "15px",
                            }}
                          >
                            {definition.examples.map((example, exampleIndex) => (
                              <li key={exampleIndex}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ flex: 5 }}>
                                    <Danger html={example.example} />
                                  </div>
                                  <span style={{ flex: 1 }} />
                                  <div style={{ flex: 5 }}>
                                    <Danger html={example.translation} />
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ol>
                </div>
              ))}
            </>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};
