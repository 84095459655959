import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addBookFromFile } from "../utils/books";

const HandleShare = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleShare = async () => {
      if ("launchQueue" in window && (window as any).launchQueue.setConsumer) {
        (window as any).launchQueue.setConsumer(
          async (launchParams: { files: any[] }) => {
            if (!launchParams.files.length) return;

            for (const fileHandle of launchParams.files) {
              const file = await fileHandle.getFile();
              if (
                file.type === "application/epub+zip" ||
                file.name.endsWith(".epub")
              ) {
                console.log("adding file", file);
                await addBookFromFile(file);
              }
            }

            navigate("/");
          }
        );
      } else {
        console.error("launchQueue is not supported in this browser.");
      }
    };

    handleShare();
  }, [navigate]);

  return <div>Adding book...</div>;
};

export default HandleShare;
